import {AxiosService} from "./axios";
import {WalletsPageDto} from "../models/common/wallets/WalletsPageDto";
import {WalletsQueryDto} from "../models/common/wallets/WalletsQueryDto";

export class WalletsService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить список кошельков
     */
    async getList(options: WalletsQueryDto): Promise<WalletsPageDto> {
        try {
            const response = await this.axios.post('/wallets/filtering', options);
            return Promise.resolve(response.data as WalletsPageDto);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
