import {ExtendedPaginationProps} from "./ExtendedPaginationProps";
import React, {useMemo, useState} from "react";
import {Button, Input, Select} from "@fluentui/react-components";
import {ArrowRight20Regular} from "@fluentui/react-icons";
import {PaginationButtons} from "../PaginationButtons";
import {Stack} from "@fluentui/react";

/**
 * Компонент расширенной пагинации
 * @param props Параметры пагинации
 * @constructor
 */
const ExtendedPagination: React.FC<ExtendedPaginationProps> = (props) => {
    const [searchPage, setSearchPage] = useState<number>()

    const totalCountPages = useMemo(() => {
        const total = Math.ceil(props.total / props.take)

        return total > 0 ? total : 1
    }, [props.total, props.take])

    const currentPage = useMemo(() => {
        return (props.offset + props.take) / props.take
    }, [props.offset, props.take])

    const onPageNumberChange = (pageNumber: number) => {
        if (pageNumber < 1 || pageNumber > totalCountPages) return

        props.onChangePage({take: props.take, offset: props.take * (pageNumber - 1)})
    }

    return (
        <Stack horizontal={true} tokens={{childrenGap: '1em'}}>
            <Select onChange={(_, data) => props.onChangePage({take: Number(data.value), offset: 0})} value={props.take} disabled={props.isLoading}>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>75</option>
                <option>100</option>
                <option>200</option>
                <option>300</option>
            </Select>

            <Input type={"number"} min={1} placeholder={"Go to page:"}
                   onChange={(_, data) => {
                       setSearchPage(Number(data.value))
                   }}
                   value={searchPage ? String(searchPage) : ''}
                   contentAfter={<Button icon={<ArrowRight20Regular/>} appearance={"transparent"} onClick={() => onPageNumberChange(searchPage ?? 1)} disabled={props.isLoading || searchPage === undefined} />}
                   disabled={props.isLoading}
            />

            <PaginationButtons currentPage={currentPage}
                               totalPages={totalCountPages}
                               totalCount={props.total}
                               onNextClick={() => onPageNumberChange(currentPage + 1)}
                               onPrevClick={() => onPageNumberChange(currentPage - 1)}
                               isLoading={props.isLoading}
            />
        </Stack>
    )
}

export default ExtendedPagination;
