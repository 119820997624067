import React, {useEffect} from "react";
import {
    Button,
    Card,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from "@fluentui/react-components";
import {NoItems} from "../../NoItems";
import {LogOpenDetailsButton} from "../../LogOpenDetailsButton";
import {useServices, useStore} from "../../../hooks";
import {observer} from "mobx-react-lite";
import {ArrowDownload20Regular} from "@fluentui/react-icons";
import {useToast} from "../../../utils/ToastProvider";
import {WALLETS_URL} from "../../../shared/constUrl";

/**
 * Список кошельков
 * @constructor
 */
const WalletsList: React.FC = () => {
    const services = useServices();
    const store = useStore();
    const {showToast} = useToast();

    /**
     * Загрузка списка кошельков
     */
    useEffect(() => {
        (async() => {
            await store.walletsStore.fetchData();
        })()
    }, [store.walletsStore.params]);

    const onWalletDownload = async (id: number) => {
        try {
            await services.fileDownloadService.downloadFile(WALLETS_URL + '/download/' + id.toString());
        } catch (error: any) {
            showToast("Error", "Something went wrong during requesting a wallet", "error");
        }
    };

    return (<>
        <Card style={{width: '100%', padding: "0.3em"}}>
            <Table
                style={{width: '100%'}}
                as={"table"}
            >
                <TableHeader as={"thead"}>
                    <TableRow as="tr" style={{
                        display: 'grid',
                        gridTemplateColumns: '100px minmax(200px, 1fr) 100px', // Используем minmax для адаптации
                        gap: '10px',
                        alignItems: 'center'
                    }}>
                        {columns.map((column) => (
                            <TableHeaderCell key={column.columnKey} as={"th"} style={{
                                display: 'flex',
                                justifyContent: 'center', // Горизонтальная центровка
                                alignItems: 'center',     // Вертикальная центровка
                            }}>
                                {column.label}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody as={"tbody"}>
                    {store.walletsStore.totalItems === 0 ? (
                        <TableRow as={"tr"} style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                            height: 'auto',
                            alignItems: 'center' // Вертикальная центровка для пустых строк
                        }}>
                            <TableCell as={"td"} style={{
                                display: 'flex',
                                justifyContent: 'center', // Горизонтальная центровка
                                alignItems: 'center',
                                padding: '2em' // Вертикальная центровка
                            }}>
                                <NoItems/>
                            </TableCell>
                        </TableRow>
                    ) : (
                        store.walletsStore.wallets.map((item) => (
                            <TableRow as={"tr"} key={item.id} style={{
                                display: 'grid',
                                gridTemplateColumns: '100px minmax(200px, 1fr) 100px',
                                gap: '5px',
                                height: 'auto',
                                alignItems: 'center' // Центровка контента по вертикали
                            }}>
                                <TableCell as={"td"} style={{
                                    display: "flex",
                                    justifyContent: "flex-start", // Выровнять имя кошелька слева
                                    alignItems: "center"
                                }}>
                                    <TableCellLayout>
                                        {item.name}
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell as={"td"} style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end', // Выровнять кнопки справа
                                    alignItems: 'center',
                                    gap: '10px' // Пространство между кнопками
                                }}>
                                    <Button icon={<ArrowDownload20Regular/>}
                                            appearance={'primary'}
                                            onClick={() => onWalletDownload(item.fileId)}
                                    />
                                    <LogOpenDetailsButton logId={item.botId}/>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </Card></>);
}

// Перенос columns выше для улучшения порядка
const columns = [
    {columnKey: "name", label: "Name"},
    {columnKey: "actions", label: "Actions"},
];

/**
 * Имя отображаемое при отладке
 */
WalletsList.displayName = 'WalletsList';
export default observer(WalletsList);
