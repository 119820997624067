import {makeStyles, shorthands} from "@fluentui/react-components";

/**
 * Стили для компонента информации о логе
 */
export default makeStyles({
    infoCard: {
        width: '100%',
        maxWidth: '800px',
        padding: '20px',
        ...shorthands.borderRadius('8px'),
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        ...shorthands.border('1px', 'solid', '#ddd'),
    },
    info: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px',
    },
    centerItem: {
        display: 'flex',
        alignItems: 'center'
    },
    modalDialog: {
        maxHeight: '80vh'
    },
    actions: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
})
