import {makeStyles} from "@fluentui/react-components";

/**
 * Стили для компонента-диалога с подтверждением действия
 */
export default makeStyles({
    modalDialog: {
        maxHeight: '80vh'
    },
    contentPadding: {
        marginBottom: '1em'
    }
})
