import React from "react";
import {LogLinksProps} from "./LogLinksProps";
import {Stack} from "@fluentui/react";
import {SourceType} from "../../../models/common/tags";
import {Image, Tooltip} from "@fluentui/react-components";
import {CheckmarkCircle16Regular, ErrorCircle16Regular, ChevronRight20Filled, ChevronLeft20Filled} from "@fluentui/react-icons";
import {StackOverflow} from "../../StackOverflow/StackOverflow";

/**
 * Компонент для отображения ссылок на логи
 * @param props
 * @constructor
 */
export const LogLinks: React.FC<LogLinksProps> = (props) => {
    return (
        <StackOverflow limitOverflow={10} tokens={{childrenGap: '4px', padding: '10px'}} verticalAlign={'center'} showMore={(
            <Tooltip content={'Show more'} relationship={'label'} withArrow>
                <ChevronRight20Filled style={{marginLeft: '5px', cursor: 'pointer'}} />
            </Tooltip>
        )} hide={(
            <Tooltip content={'Hide'} relationship={'label'} withArrow>
                <ChevronLeft20Filled style={{marginLeft: '5px', cursor: 'pointer'}} />
            </Tooltip>
        )} horizontal wrap>
            {props.tags.map((tag) => {
                if (tag.sources.includes(SourceType.Files) || !tag.key.includes('.')) return null;

                return (
                    <Tooltip content={(
                        <Stack verticalAlign={"center"}>
                            <span><strong>Link:</strong> {tag.key}</span>
                            <Stack tokens={{childrenGap: 4}} verticalAlign={"center"} horizontal>
                                <strong>Passwords: </strong>
                                {tag.sources.includes(SourceType.Passwords) ? <CheckmarkCircle16Regular color={'green'} /> : <ErrorCircle16Regular color={'red'}/>}
                            </Stack>
                            <Stack tokens={{childrenGap: 4}} verticalAlign={"center"} horizontal>
                                <strong>Cookies: </strong>
                                {tag.sources.includes(SourceType.Cookies) ? <CheckmarkCircle16Regular color={'green'} /> : <ErrorCircle16Regular color={'red'}/>}
                            </Stack>
                        </Stack>
                    )} relationship={"description"} withArrow>
                        <Image src={`https://icons.duckduckgo.com/ip3/${tag.key}.ico`} width={16} height={16} />
                    </Tooltip>
                )
            })}
        </StackOverflow>
    )
}
