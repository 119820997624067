import React, {useCallback, useState} from "react";
import ErrorsList from "../../../components/ErrorsList/ErrorsList";
import {BinRecycle20Regular, Search20Regular} from "@fluentui/react-icons";
import {
    Button,
    Field,
    Input,
    Select,
    tokens
} from "@fluentui/react-components";
import {ToolsContainer} from "../../../containers/ToolsContainer";
import useStyles from "./ErrorsToolsStyles";
import {useServices, useStore} from "../../../hooks";
import {observer} from "mobx-react-lite";
import {ExtendedPagination} from "../../../components/ExtendedPagination";
import {DataErrorTypeNames} from "../../../models/admin/dataErrors/dataErrorTypes";
import {ConfirmationDialog} from "../../../components/ConfirmationDialog/ConfirmationDialog";
import {useToast} from "../../../utils/ToastProvider";
import {ApiError} from "../../../models";
import {GetMessageByCode} from "../../../utils";

/**
 * Компонент-тулбар для Data Errors
 * @constructor
 */
const ErrorsTools: React.FC = () => {
    const styles = useStyles();
    const store = useStore();
    const services = useServices();

    const [keywordField, setKeywordField] = useState<string>('')

    const {showToast} = useToast();

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = useCallback((error: ApiError) => {
        showToast('Error!', GetMessageByCode(error.code), "error");
    }, [showToast]);

    const onSearch = useCallback(async() => {
        store.dataErrorsStore.params.keyword = keywordField;

        try {
            await store.dataErrorsStore.fetchData(true)
        } catch (err: any) {
            showErrorNotify(err)
        }
    }, [store.dataErrorsStore, keywordField, showErrorNotify])

    const onDelete = useCallback(async() => {
        try {
            await services.adminDataErrorService.deleteMany(store.dataErrorsStore.params)
            await store.dataErrorsStore.fetchData(true)
        } catch (err: any) {
            showErrorNotify(err)
        }
    }, [services.adminDataErrorService, store.dataErrorsStore, showErrorNotify])

    return (
        <ToolsContainer>
            <div className={styles.controlContainer}>
                <Field className={styles.fieldBlock} label={'Error Type:'}>
                    <Select disabled={store.loaderStore.loading} value={store.dataErrorsStore.params.dataTypes} onChange={(_, e) => store.dataErrorsStore.params = {...store.dataErrorsStore.params, take: 50, offset: 0, dataTypes: Number(e.value)}}>
                        <option value={undefined}>All</option>
                        {Object.entries(DataErrorTypeNames).map(([index, value]) => (
                            <option key={value} value={index}>{value}</option>
                        ))}
                    </Select>
                </Field>

                <Field className={styles.fieldBlock} label={'Keyword:'}>
                    <Input type={'text'} value={keywordField} onChange={(e) => setKeywordField(e.target.value)} disabled={store.loaderStore.loading} />
                </Field>

                <Button icon={<Search20Regular/>} disabled={store.loaderStore.loading} onClick={onSearch}>Search</Button>

                <ConfirmationDialog
                    triggerButton={<Button icon={<BinRecycle20Regular />} disabled={store.loaderStore.loading || store.dataErrorsStore.totalItems === 0} style={{color: tokens.colorPaletteDarkOrangeForeground1}}>Delete</Button>}
                    title={'Delete errors?'}
                    content={'If you have specified filters, errors matching the filters will be deleted. Check the specified filters, click the search button to confirm the filters. Deleted errors cannot be restored!'}
                    confirmationText={'I understand, delete.'}
                    onSubmit={onDelete}
                />
            </div>
            <ExtendedPagination total={store.dataErrorsStore.totalItems}
                                offset={store.dataErrorsStore.params.offset}
                                take={store.dataErrorsStore.params.take}
                                onChangePage={(paginationParams) => store.dataErrorsStore.params = {...store.dataErrorsStore.params, ...paginationParams}}
                                isLoading={store.loaderStore.loading} />
        </ToolsContainer>
    )
}

/**
 * Имя отображаемое при отладке
 */
ErrorsList.displayName = "ErrorsTools";
export default observer(ErrorsTools);
