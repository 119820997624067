import React from "react";
import {ToolsContainer} from "../../../containers/ToolsContainer";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../hooks";
import {Button, Select} from "@fluentui/react-components";
import {services} from "../../../services";
import {useSearchParams} from "react-router-dom";
import {WALLETS_URL} from "../../../shared/constUrl";
import {ArrowDownload20Regular} from "@fluentui/react-icons";
import {ExtendedPagination} from "../../ExtendedPagination";

/**
 * Инструменты управления данными на странице "Кошельки"
 * @constructor
 */
const WalletsTools: React.FC = () => {
    const store = useStore();
    const [searchParams] = useSearchParams();

    /**
     * Логика скачивания файла
     */
    const handleDownload = async () => {
        const logId = searchParams.get("botId") || "0";
        const params = new URLSearchParams({botId: logId});

        // Формируем полный URL с параметрами
        const urlWithParams = `${WALLETS_URL}/download?${params.toString()}`;
        await services.fileDownloadService.downloadFile(urlWithParams);
    };

    return (
        <ToolsContainer>
            {/* Контейнер для всей строки */}
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                {/* Левый блок (фильтры и кнопки управления кошельком) */}
                <div style={{display: "flex", alignItems: "center", gap: "1em"}}>
                    {/* Обёртка для выбора с лейблом */}
                    <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
                        <label htmlFor="wallet-select">Wallet name:</label>
                        <Select id="wallet-select" value={store.walletsStore.params.walletName} onChange={(e) => store.walletsStore.params = {...store.walletsStore.params, walletName: e.target.value}} disabled={store.loaderStore.loading}>
                            <option key="all" value="">
                                All
                            </option>
                            {store.walletsStore.options.map((val) => (
                                <option key={val} value={val}>
                                    {val}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <Button icon={<ArrowDownload20Regular/>} appearance="primary" onClick={handleDownload}
                            style={{marginRight: "1em"}} disabled={store.loaderStore.loading}>
                        Download
                    </Button>
                </div>
            </div>
            <ExtendedPagination onChangePage={(paginationParams) => store.walletsStore.params = {...store.walletsStore.params, ...paginationParams}}
                                isLoading={store.loaderStore.loading}
                                total={store.walletsStore.totalItems}
                                take={store.walletsStore.params.take} offset={store.walletsStore.params.offset}
            />
        </ToolsContainer>
    );
};

/**
 * Имя, отображаемое при отладке
 */
WalletsTools.displayName = "WalletsTools";

export default observer(WalletsTools);
