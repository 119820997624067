import React, {useCallback, useState} from "react";
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogBody,
    DialogSurface,
    DialogTitle,
    Input,
    Select, tokens
} from "@fluentui/react-components";
import {
    Add20Filled,
    Search20Regular,
    CloudArrowUp20Filled,
    BinRecycle20Filled
} from "@fluentui/react-icons";
import {ToolsContainer} from "../../../containers/ToolsContainer";
import {useServices, useStore} from "../../../hooks";
import {SelectedCount} from "../../../components/SelectedCount";
import useStyles from "./TagsToolsStyles"
import {ExtendedPagination} from "../../../components/ExtendedPagination";
import {observer} from "mobx-react-lite";
import {TagCreateVm} from "../../../models/common/tags";
import {GetMessageByCode} from "../../../utils";
import {ApiError} from "../../../models";
import {useToast} from "../../../utils/ToastProvider";
import {Stack} from '@fluentui/react'

const TagsTools: React.FC = () => {
    const store = useStore();
    const styles = useStyles();
    const services = useServices();
    const {showToast} = useToast();

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = useCallback((error: ApiError) => {
        showToast('Error!', GetMessageByCode(error.code), "error");
    }, [showToast]);

    // Статусы отображения диалогов
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

    // Поля для формы создания тега
    const [newTagName, setNewTagName] = useState('');
    const [newTagKeyword, setNewTagKeyword] = useState('');
    const [newTagColor, setNewTagColor] = useState('#0078D4'); // Синий цвет по умолчанию
    const [searchInFiles, setSearchInFiles] = useState(false);

    // Поля для импорта файлов
    const [importFile, setImportFile] = useState<File | null>(null);
    const [importTarget, setImportTarget] = useState('1'); // "Browser" по умолчанию

    const showSelectedCount = useCallback(() => {
        if (store.tagsStore.selectedTags.length > 0) {
            return <SelectedCount count={store.tagsStore.selectedTags.length}/>
        }
    }, [store.tagsStore.selectedTags.length]);

    /**
     * Обработчик нажатия кнопки поиск
     */
    const onSearch = useCallback(() => {
        (async() => {
            await store.tagsStore.fetchData(true)
        })()
    }, [store.tagsStore])

    /**
     * Обработчик создания тега
     */
    const handleCreateTag = async () => {
        const newTag: TagCreateVm = {
            name: newTagName,
            keyword: newTagKeyword,
            color: newTagColor,
            inFile: searchInFiles,
        };

        try {
            await services.tagsService.createTags(newTag);
            setIsCreateDialogOpen(false);
            await store.tagsStore.fetchData(true);
        } catch (error: any) {
            showErrorNotify(error);
        }
    };

    /**
     * Обработчик импорта тегов с файла
     */
    const handleImportTags = async () => {
        if (importFile) {
            const formData = new FormData();
            formData.append('file', importFile);

            try {
                await services.tagsService.uploadTagsFromFile(formData, Number(importTarget));
                setIsImportDialogOpen(false);
                await store.tagsStore.fetchData(true);
            } catch (error: any) {
                showErrorNotify(error);
            }
        }
    };

    /**
     * Обработчик удаления выделенных тегов
     */
    const onDeleteButton = () => {
        alert('not implemented exception')
    }

    return (
        <ToolsContainer>
            <div className={styles.controlContainer}>
                <Select value={store.tagsStore.params.target} onChange={(_, e) => store.tagsStore.params.target = Number(e.value)} disabled={store.loaderStore.loading}>
                    <option>All</option>
                    <option value={1}>Browser</option>
                    <option value={2}>File</option>
                </Select>

                <div className={styles.controlTextField}>
                    <label>Keyword:</label>
                    <Input placeholder={'Enter keyword'} value={store.tagsStore.params.keyword} onChange={(e) => store.tagsStore.params.keyword = e.target.value} disabled={store.loaderStore.loading}></Input>
                </div>

                <Button icon={<Search20Regular/>} onClick={onSearch} disabled={store.loaderStore.loading}>Search</Button>

                <Button icon={<Add20Filled />} appearance="primary" onClick={() => setIsCreateDialogOpen(true)} disabled={store.loaderStore.loading}>
                    Create new
                </Button>

                <Button icon={<CloudArrowUp20Filled />} onClick={() => setIsImportDialogOpen(true)} disabled={store.loaderStore.loading}>
                    Import from file
                </Button>

                <Button
                    style={{
                        color: tokens.colorPaletteDarkOrangeForeground1
                    }}
                    onClick={onDeleteButton}
                    disabled={store.loaderStore.loading || store.tagsStore.selectedTags.length === 0}
                    icon={<BinRecycle20Filled/>}>Delete</Button>

                {showSelectedCount()}
            </div>

            <div className={styles.controlPagination}>
                <ExtendedPagination total={store.tagsStore.totalItems}
                                    offset={store.tagsStore.params.offset}
                                    take={store.tagsStore.params.take}
                                    onChangePage={(paginationParams) => store.tagsStore.params = {...store.tagsStore.params, ...paginationParams}}
                                    isLoading={store.loaderStore.loading}
                />
            </div>

            {/* Диалог создания тега */}
            <Dialog open={isCreateDialogOpen} onOpenChange={(event, data) => setIsCreateDialogOpen(data.open)}>
                <DialogSurface>
                    <DialogTitle>Add new tag</DialogTitle>
                    <DialogBody className={styles.modalBody}>
                        <Stack tokens={{childrenGap: 5}}>
                            <Input placeholder="Tag name" value={newTagName} onChange={(e, data) => setNewTagName(data.value)} />
                            <Input placeholder="Keyword" value={newTagKeyword} onChange={(e, data) => setNewTagKeyword(data.value)} />
                            <Input  value={newTagColor} onChange={(e) => setNewTagColor(e.target.value)} />
                            <Checkbox label={'Search in files'} checked={searchInFiles} onChange={() => setSearchInFiles(!searchInFiles)} />
                        </Stack>
                    </DialogBody>
                    <DialogActions>
                        <Button appearance="primary" onClick={handleCreateTag}>Add</Button>
                        <Button onClick={() => setIsCreateDialogOpen(false)}>Cancel</Button>
                    </DialogActions>
                </DialogSurface>
            </Dialog>

            {/* Диалог импорта тегов */}
            <Dialog open={isImportDialogOpen} onOpenChange={(event, data) => setIsImportDialogOpen(data.open)}>
                <DialogSurface>
                    <DialogTitle>Import tags from file</DialogTitle>
                    <DialogBody className={styles.modalBody}>
                        <Stack tokens={{childrenGap: 10}}>
                            <Select value={importTarget} onChange={(e, option) => setImportTarget(option.value)}>
                                <option value={1}>Browser</option>
                                <option value={2}>File</option>
                            </Select>
                            <input type="file" onChange={(e) => setImportFile(e.target.files?.[0] || null)} />
                        </Stack>
                    </DialogBody>
                    <DialogActions>
                        <Button appearance="primary" onClick={handleImportTags}>Upload</Button>
                        <Button onClick={() => setIsImportDialogOpen(false)}>Cancel</Button>
                    </DialogActions>
                </DialogSurface>
            </Dialog>
        </ToolsContainer>
    )
}

export default observer(TagsTools);
