import React from "react";
import {Layout} from "../../../containers/Layout";
import TagsTools from "./TagsTools";
import {TagsList} from "../../../components/TagsList";

/**
 * Компонент-страница "Теги"
 * @constructor
 */
const TagsPage: React.FC = () => {
    return (
        <Layout pageTittle={"Tags"} anotherBackground={true}>
            <TagsTools />
            <TagsList />
        </Layout>
    );
}

TagsPage.displayName = "TagsPage";
export default TagsPage;
