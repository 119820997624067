import {makeAutoObservable} from "mobx";
import {Password, PasswordsFiltering, PasswordsPage} from "../models/common";
import {services} from "../services";

/**
 * Хранилище для страницы паролей
 */
export class PasswordsStore {
    /**
     * Конструктор хранилища
     */
    constructor() {
        makeAutoObservable(this)
    }

    /**
     * Общее количество паролей
     * @private
     */
    private _totalItems: number = 0;

    /**
     * Получить общее количество паролей
     */
    public get totalItems() {
        return this._totalItems;
    }

    /**
     * Параметры фильтрации
     * @private
     */
    private _params: PasswordsFiltering = {
        take: 50,
        offset: 0
    };

    /**
     * Получить параметры фильтрации
     */
    public get params() {
        return this._params;
    }

    /**
     * Установить новые параметры фильтрации
     * @param value
     */
    public set params(value: PasswordsFiltering) {
        this._params = value;
    }

    /**
     * Пароли
     * @private
     */
    private _passwords: Password[] = [];

    /**
     * Получить пароли
     */
    public get passwords() {
        return this._passwords;
    }

    /**
     * Доступные браузеры для поиска
     * @private
     */
    private _browserOptions: string[] = [];

    /**
     * Получить доступные браузеры для поиска
     */
    public get browserOptions() {
        return this._browserOptions;
    }

    private updateData(value: PasswordsPage) {
        this._passwords = value.passwords;
        this._browserOptions = value.browser_options ?? [];
        this._totalItems = value.total;
    }

    public async fetchData(resetPage: boolean) {
        if (resetPage) {
            this._params.take = 50;
            this._params.offset = 0;
        }

        const passwordsPage = await services.passwordService.getPasswords(this._params);

        this.updateData(passwordsPage)
    }
}
