import React from "react";
import {Layout} from "../../../containers/Layout";
import PasswordsTools from "./PasswordsTools";
import {observer} from "mobx-react-lite";
import {PasswordsList} from "../../../components/PasswordsList";

/**
 * Компонент-страница "Пароли"
 * @constructor
 */
const PasswordsPage: React.FC = () => {
    return (
        <Layout pageTittle={"Passwords"} anotherBackground={true}>
            <PasswordsTools />
            <PasswordsList />
        </Layout>
    );
}

PasswordsPage.displayName = "PasswordPage";
export default observer(PasswordsPage);
