import {makeAutoObservable} from "mobx";
import {WalletsDto} from "../models/common/wallets/WalletsDto";
import {WalletsQueryDto} from "../models/common/wallets/WalletsQueryDto";
import {services} from "../services";
import {WalletsPageDto} from "../models/common/wallets/WalletsPageDto";

export class WalletsStore {
    /**
     * Конструктор хранилища кошельков
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Общее количество кошельков
     * @private
     */
    private _totalItems: number = 0;

    /**
     * Получить общее количество кошельков
     */
    public get totalItems() {
        return this._totalItems;
    }

    /**
     * Параметры фильтрации
     * @private
     */
    private _params: WalletsQueryDto = {
        take: 50,
        offset: 0
    }

    /**
     * Получить параметры фильтрации
     */
    public get params() {
        return this._params;
    }

    public set params(value: WalletsQueryDto) {
        this._params = value;
    }

    /**
     * Кошельки
     * @private
     */
    private _wallets: WalletsDto[] = [];

    /**
     * Получить кошельки
     */
    public get wallets() {
        return this._wallets;
    }

    /**
     * Список доступных кошельков для фильтрации
     * @private
     */
    private _options: string[] = [];

    /**
     * Получить список доступных кошельков для фильтрации
     */
    public get options() {
        return this._options;
    }

    public updateData(walletsPage: WalletsPageDto) {
        this._wallets = walletsPage.wallets;
        this._options = walletsPage.options;
        this._totalItems = walletsPage.total;
    }

    public async fetchData() {
        const walletsPage = await services.walletsService.getList(this._params)
        this.updateData(walletsPage)
    }
}
