import React, {useEffect} from "react";
import {useServices, useStore} from "../../hooks";
import {observer} from "mobx-react-lite";
import {
    Button,
    Card,
    Table,
    TableBody,
    TableCell, TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    tokens
} from "@fluentui/react-components";
import {ArrowDownload20Regular, BinRecycle20Regular, Replay20Regular} from "@fluentui/react-icons";
import {DataErrorTypeNames} from "../../models/admin/dataErrors/dataErrorTypes";
import {useToast} from "../../utils/ToastProvider";
import {ADMIN_DATA_ERRORS_URL} from "../../shared/constUrl";
import useStyles from "./ErrorsListStyles"
import {Stack} from "@fluentui/react";

/**
 * Список ошибок
 * @constructor
 */
const ErrorsList: React.FC = () => {
    const service = useServices();
    const styles = useStyles();
    const store = useStore();
    const {showToast} = useToast();

    /**
     * Загрузка списка ошибок
     */
    useEffect(() => {
        (async() => {
            try {
                await store.dataErrorsStore.fetchData(false)
            } catch (error: any) {
                showToast("Error", "Problem during retrieving error list.", "error");
            }
        })()
    }, [store.dataErrorsStore, store.dataErrorsStore.params, showToast]);

    /**
     * Поставить ошибку в очередь
     * @param errorId
     */
    const reloadToQueue = async (errorId: number) => {
        try {
            await service.adminDataErrorService.reloadToQueue(errorId);
            showToast("Done!", "Item reloaded to queue", "success");
        } catch (error: any) {
            showToast("Error", "Something went wrong...", "error");
        }
    }

    /**
     * Удаление ошибки
     * @param errorId ID ошибки
     */
    const deleteItem = async (errorId: number) => {
        try {
            await service.adminDataErrorService.deleteItem(errorId);
            await store.dataErrorsStore.fetchData(false)
            showToast("Done!", "Error files deleted successfully", "success");
        } catch (error: any) {
            showToast("Error", "Something went wrong...", "error");
        }
    }

    /**
     * Скачать информацию об ошибке
     * @param errorId
     */
    const downloadItem = async (errorId: number) => {
        try {
            const urlWithParams = `${ADMIN_DATA_ERRORS_URL}/download/${errorId}`;
            await service.fileDownloadService.downloadFile(urlWithParams);
        } catch (error: any) {
            showToast("Error", "Something went wrong...", "error");
        }
    }

    return (
        <Card style={{width: '100%'}}>
            <Table className={styles.table} size={"small"}>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Id</TableHeaderCell>
                        <TableHeaderCell>Type</TableHeaderCell>
                        <TableHeaderCell>IP</TableHeaderCell>
                        <TableHeaderCell>Details</TableHeaderCell>
                        <TableHeaderCell>Path to file</TableHeaderCell>
                        <TableHeaderCell>Occurred at</TableHeaderCell>
                        <TableHeaderCell>Actions</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {store.dataErrorsStore.errors.map((item) => (
                        <TableRow key={item.errorId}>
                            <TableCell>
                                <TableCellLayout>
                                    {item.errorId}
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <TableCellLayout>
                                    {DataErrorTypeNames[item.type] || "Unknown"}
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <TableCellLayout>
                                    {item.ip}
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <TableCellLayout>
                                    {item.details}
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <TableCellLayout>
                                    {item.pathToFile}
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <TableCellLayout>
                                    {item.createdAt}
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <Stack horizontal={true} tokens={{childrenGap: 5}}>
                                    <Button disabled={store.loaderStore.loading}
                                                   onClick={async () => {
                                                       await downloadItem(item.errorId)
                                                   }}
                                                   appearance={"primary"} icon={<ArrowDownload20Regular/>}/>
                                    <Button disabled={store.loaderStore.loading}
                                                   icon={<Replay20Regular/>}
                                                   onClick={() => reloadToQueue(item.errorId)}
                                    />
                                    <Button disabled={store.loaderStore.loading}
                                                   style={{
                                                       background: tokens.colorPaletteDarkOrangeBackground1
                                                   }}
                                                   onClick={() => deleteItem(item.errorId)}
                                                   icon={<BinRecycle20Regular/>}/>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
)
}

/**
* Имя отображаемое при отладке
*/
ErrorsList.displayName = "ErrorsList";
export default observer(ErrorsList);
