import React, {Children, useState} from "react";
import {Stack} from "@fluentui/react";
import {StackOverflowProps} from "./StackOverflowProps";

/**
 * Компонент для отображения стека с возможностью скрытия и показа элементов
 * @param props
 * @constructor
 */
export const StackOverflow: React.FC<StackOverflowProps> = (props) => {
    const [overflow, setOverflow] = useState(true)

    return (
        <Stack {...props}>
            {Children.toArray(props.children).slice(0, overflow ? props.limitOverflow : Children.count(props.children))}
            {(Children.count(props.children) > props.limitOverflow && overflow) ? <Stack onClick={() => setOverflow(false)}>{props.showMore}</Stack> : null}
            {(Children.count(props.children) > props.limitOverflow && !overflow) ? <Stack onClick={() => setOverflow(true)}>{props.hide}</Stack> : null}
        </Stack>
    )
}
