import {TagCreateVm, TagFilteringDto, TagType} from "../models/common/tags";
import {AxiosService} from "./axios";
import {TagsPageDto} from "../models/common/tags/TagsPageDto";
import {ApiResult} from "../models";

export class TagsService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получение тегов по фильтрам
     */
    async getTagsFilterInfo(filter: TagFilteringDto): Promise<TagsPageDto> {
        try {
            const resp = await this.axios.post<TagsPageDto>("/tags/list/filter", filter);
            return Promise.resolve(resp.data);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * создать тег
     */
    async createTags(create: TagCreateVm): Promise<ApiResult<any>> {
        try {
            const resp = await this.axios.post("/tags/create", create);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * удалить тег по ид
     */
    async deleteTag(id: string): Promise<void> {
        try {
            await this.axios.post("/tags/delete/" + id);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * удалить несколько тегов
     */
    /*async deleteManyTags(filter: TagFilteringDto): Promise<void> {
        try {
            await this.axios.post("/tags/delete-many");
        } catch (error: any) {
            return Promise.reject(error);
        }
    }*/

    /**
     * загрузить теги из файла
     */
    async uploadTagsFromFile(file: FormData, type: TagType): Promise<void> {
        try {
            await this.axios.uploadFile("/tags/upload-import-file/" + type, file);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
