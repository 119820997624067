import React, {useCallback, useEffect} from "react";
import {
    Badge, Button,
    Card,
    Table,
    TableBody, TableCell, TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableSelectionCell, tokens
} from "@fluentui/react-components";
import NoItems from "../NoItems/NoItems";
import {useServices, useStore} from "../../hooks";
import {TagDto, TagType} from "../../models/common/tags";
import {observer} from "mobx-react-lite";
import {GetMessageByCode} from "../../utils";
import {ApiError} from "../../models";
import {useToast} from "../../utils/ToastProvider";
import {DeleteRegular} from "@fluentui/react-icons";

/**
 * Компонент списка тегов
 * @constructor
 */
const TagsList: React.FC = () => {
    const store = useStore();
    const services = useServices();
    const {showToast} = useToast();

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = useCallback((error: ApiError) => {
        showToast('Error!', GetMessageByCode(error.code), "error");
    }, [showToast]);

    /**
     * Эффект первоначальной загрузки тегов
     */
    useEffect(() => {
        (async() => {
            await store.tagsStore.fetchData(false)
        })()
    }, [store.tagsStore, store.tagsStore.params]);

    /**
     * Обработчик для выбора/снятия выделения строки
     * @param item Log
     */
    const handleRowSelect = useCallback((item: TagDto) => {
        const prevSelectedIds = store.tagsStore.selectedTags;

        store.tagsStore.selectedTags = prevSelectedIds.includes(item.id)
            ? prevSelectedIds.filter((id: number) => id !== item.id)
            : [...prevSelectedIds, item.id];
    }, [store.tagsStore]);

    /**
     * Обработчик для выбора/снятия выделения всех строк
     */
    const handleSelectAll = useCallback(() => {
        if (store.tagsStore.selectedTags.length === store.tagsStore.tags.length) {
            store.tagsStore.selectedTags = [];
        } else {
            store.tagsStore.selectedTags = store.tagsStore.tags.map((tag) => tag.id);
        }
    }, [store.tagsStore]);

    /**
     * Обработчик удаления тега
     * @param id ID тега
     */
    const handleDeleteTag = async (id: number) => {
        try {
            await services.tagsService.deleteTag(String(id));
            await store.tagsStore.fetchData(false)
        } catch (error: any) {
            showErrorNotify(error)
        }
    };

    return (
        <Card style={{width: '100%', padding: "0.3em"}}>
            <Table size={"small"}>
                <TableHeader>
                    <TableRow>
                        <TableSelectionCell
                            checkboxIndicator={{"aria-label": "Select all rows"}}
                            checked={store.tagsStore.selectedTags.length === store.tagsStore.tags.length}
                            onClick={handleSelectAll}
                        />
                        <TableHeaderCell>Tag</TableHeaderCell>
                        <TableHeaderCell>Target</TableHeaderCell>
                        <TableHeaderCell>Key</TableHeaderCell>
                        <TableHeaderCell>Actions</TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {(store.tagsStore.totalItems === 0)
                        ? <TableRow>
                            <TableCell colSpan={5}>
                                <NoItems/>
                            </TableCell>
                        </TableRow>
                        : store.tagsStore.tags.map((item) => (
                            <TableRow key={item.id}>
                                <TableSelectionCell
                                    checkboxIndicator={{"aria-label": "Select row"}}
                                    checked={store.tagsStore.selectedTags.includes(item.id)}
                                    onClick={() => handleRowSelect(item)}
                                />

                                <TableCell>
                                    <TableCellLayout>
                                        <Badge style={{ backgroundColor: item.color}}>{item.name}</Badge>
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        {TagType[item.autoTagType]}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        {item.key}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        <Button appearance={'primary'} style={{background: tokens.colorPaletteDarkOrangeForeground1}} onClick={() => handleDeleteTag(item.id)} icon={<DeleteRegular />}></Button>
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Card>
    );
}

export default observer(TagsList);
