import React, {useState} from "react";
import {ConfirmationDialogProps} from "./ConfirmationDialogProps";
import {
    Button, Checkbox, Dialog, DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger
} from "@fluentui/react-components";
import useStyles from './ConfirmationDialogStyles'

/**
 * Компонент диалога с подтверждением действия
 * @param props
 * @constructor
 */
export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
    const [checked, setChecked] = useState(false)

    const styles = useStyles();

    return (
        <Dialog>
            <DialogTrigger disableButtonEnhancement>{props.triggerButton}</DialogTrigger>
            <DialogSurface>
                <DialogBody className={styles.modalDialog}>
                    <DialogTitle>{props.title}</DialogTitle>
                    <DialogContent>
                        <div className={styles.contentPadding}>
                            {props.content}
                        </div>
                        <Checkbox
                            checked={checked}
                            onChange={(e) => setChecked(e.target.checked)}
                            label={props.confirmationText}
                        />
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance={'primary'} disabled={!checked} onClick={() => {
                                setChecked(false)
                                props.onSubmit()
                            }}>Continue</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}
