import React, {useEffect, useState} from 'react';
import {
    Spinner
} from '@fluentui/react-components';
import {useServices} from '../../hooks';
import {BotInfo} from '../../models/common';
import UseStyle from './BotInfoFormStyles';
import {useNavigate, useSearchParams} from 'react-router-dom';
import InfoCard from './InfoCard';
import {
    BookContacts24Filled,
    ClipboardTaskListLtr24Filled,
    Cookies24Filled,
    CreditCardPerson24Regular,
    Form24Filled,
    Key24Filled,
    Table24Filled,
    Wallet24Filled,
} from '@fluentui/react-icons';
import {LogDeleteButton} from '../LogsList/LogDeleteButton';
import {LogDownloadButton} from '../LogsList/LogDownloadButton';
import {COOKIE_LIST_URL, PASSWORD_URL} from '../../shared/constUrl';
import {botIdQueryParam} from "../../shared/constString";
import {BotInformationCard} from "../BotInformationCard/BotInformationCard";

const BotInfoForm: React.FC = () => {
    const [botInfo, setBotInfo] = useState<BotInfo | null>(null);
    const services = useServices();
    const styles = UseStyle();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const botId = searchParams.get('botid');

    useEffect(() => {
        (async () => {
            try {
                if (!botId) {
                    console.error('Bot ID not found in URL');
                    return;
                }
                const result = await services.botInfoService.getInfoBot(botId);
                setBotInfo(result.data);
            } catch (error: any) {
                console.error('Error fetching bot info:', error.message);
            }
        })()
    }, [services.botInfoService, botId]);

    if (!botInfo) {
        return <div><Spinner/> Loading...</div>;
    }

    return (
        <div className={styles.container}>
            <BotInformationCard botInfo={botInfo} actionButtons={
                <>
                    <LogDownloadButton logId={botInfo.id}/>
                    <LogDeleteButton logId={botInfo.id}/>
                </>
            } />

            <div className={styles.cardGrid}>
                <InfoCard
                    title="Wallets"
                    count={botInfo.walletCount}
                    color="#00BFFF"
                    icon={<Wallet24Filled/>}
                />
                <InfoCard
                    title="Extensions wallet"
                    count={botInfo.extWalletCount}
                    color="#FFA500"
                    icon={<Wallet24Filled/>}
                />
                <InfoCard
                    title="Passwords"
                    count={botInfo.passwordsCount}
                    color="#808080"
                    icon={<Key24Filled/>}
                    onClick={() => {
                        navigate(PASSWORD_URL + '?botid=' + botInfo.id);
                    }}
                />
                <InfoCard
                    title="Cookies"
                    count={botInfo.cookiesCount}
                    color="#8A2BE2"
                    icon={<Cookies24Filled/>}
                    onClick={() => {
                        navigate(COOKIE_LIST_URL + '?' + botIdQueryParam + '=' + botId)
                    }}
                />
                <InfoCard
                    title="Forms data records"
                    count={botInfo.formsCount}
                    color="#32CD32"
                    icon={<Form24Filled/>}
                />
                <InfoCard
                    title="Credit Cards"
                    count={botInfo.creditCardsCount}
                    color="#FF4500"
                    icon={<CreditCardPerson24Regular/>}
                />
                <InfoCard
                    title="Messengers"
                    count={botInfo.msgrsCount}
                    color="#1E90FF"
                    icon={<BookContacts24Filled/>}
                />
                <InfoCard
                    title="Other softs"
                    count={botInfo.otherSoftsCount}
                    color="#3CB371"
                    icon={<ClipboardTaskListLtr24Filled/>}
                />
                <InfoCard
                    title="Pass Managers | 2FA"
                    count={botInfo.pmExtCount}
                    color="#6495ED"
                    icon={<Table24Filled/>}
                />
            </div>
        </div>
    );
};

export default BotInfoForm;
