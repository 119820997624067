import {AxiosService} from "./axios";
import {DataErrorsFiltering} from "../models/admin/dataErrors/dataErrorsFiltering";
import {DataErrorsViewDto} from "../models/admin/dataErrors/dataErrorsViewDto";

/**
 * Сервис работы с ошибками данных
 */
export class AdminDataErrorsService {
    /**
     * Адрес контроллера с ошибками
     * @private
     */
    private baseUrl: string = "admin/data-errors";

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор сервиса
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить список ошибок
     */
    async getList(filter: DataErrorsFiltering): Promise<DataErrorsViewDto> {
        try {
            const response = await this.axios.post(`${this.baseUrl}/filter`, filter);
            return response.data as DataErrorsViewDto;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Поставить ошибку в очередь
     * @param errorId
     */
    async reloadToQueue(errorId: number): Promise<void> {
        try {
            await this.axios.post(this.baseUrl + '/reload', errorId);
            return Promise.resolve();
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удалить ошибку
     * @param errorId
     */
    async deleteItem(errorId: number): Promise<void> {
        try {
            await this.axios.delete(this.baseUrl + '/delete/' + errorId);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удалить ошибки по фильтрам
     * @param filter
     */
    async deleteMany(filter: DataErrorsFiltering) {
        try {
            const {take, offset, ...filteredParams} = filter;
            await this.axios.delete(`${this.baseUrl}/delete-many`, filteredParams);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
