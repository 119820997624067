import React, {ReactElement} from "react";
import {LogSummaryProp} from "./LogSummaryProp";
import {Badge, Tooltip} from "@fluentui/react-components";
import {
    cookieColor,
    creditCardColor,
    extWalletsColor,
    messengerColor,
    passwordColor,
    softColor,
    walletsColor
} from "../../shared/constColors";
import useStyles from "../LogsList/LogsListStyle";
import {SourceType} from "../../models/common/tags/";
import {v4 as uuidv4} from 'uuid';
import {Stack} from "@fluentui/react";
import {Cookies20Filled, KeyMultiple20Filled, Chat20Filled, CreditCardPerson20Filled, WindowApps20Filled, Wallet20Filled, WalletCreditCard20Filled} from "@fluentui/react-icons"

/**
 * Компонент для отображения короткой информации о логе в виде бейджей
 * с возможностью перехода в некоторые разделы
 * @constructor
 */
const LogSummary: React.FC<LogSummaryProp> = (props) => {
    const styles = useStyles();
    const badgeSize = "medium";

    /**
     * Генерируем набор бейджей для отображения
     * @param props
     */
    const generateItems = (props: LogSummaryProp): ReactElement[] => {
        let items: React.ReactElement[] = [];

        if (props.isDuplicate) {
            items.push(
                <Tooltip content={'Duplicate'} relationship={"label"} withArrow>
                    <Badge appearance={'outline'} size={badgeSize} key={"dup"} id={"dup"} style={{color: 'black'}}>!DUP!</Badge>
                </Tooltip>
            );
        }
        if (props.walletCount > 0) {
            items.push(
                <Tooltip content={'Wallets'} relationship={"label"} withArrow>
                    <Badge appearance={'outline'} size={badgeSize} key={"WalletCount"} id={"WalletCount"}
                           style={{color: walletsColor}} icon={<Wallet20Filled />}>{props.walletCount}</Badge>
                </Tooltip>
            );
        }
        if (props.extWalletCount > 0) {
            items.push(
                <Tooltip content={'eWallets'} relationship={"label"} withArrow>
                    <Badge appearance={'outline'} size={badgeSize} key={"ExtWalletCount"} id={"ExtWalletCount"}
                           style={{color: extWalletsColor}} icon={<WalletCreditCard20Filled />}>{props.extWalletCount}</Badge>
                </Tooltip>
            );
        }
        if (props.softCount > 0) {
            items.push(
                <Tooltip content={'Apps'} relationship={"label"} withArrow>
                    <Badge appearance={'outline'} size={badgeSize} key={"softCount"} id={"softCount"}
                           style={{color: softColor}} icon={<WindowApps20Filled />}>{props.softCount}</Badge>
                </Tooltip>
            );
        }
        if (props.creditCardsCount > 0) {
            items.push(
                <Tooltip content={'Credit Cards'} relationship={"label"} withArrow>
                    <Badge appearance={'outline'} size={badgeSize} key={"creditCards"} id={"creditCards"}
                           style={{color: creditCardColor}} icon={<CreditCardPerson20Filled />}>{props.creditCardsCount}</Badge>
                </Tooltip>
            );
        }
        if (props.cookiesCount > 0) {
            items.push(
                <Tooltip content={'Cookies'} relationship={"label"} withArrow>
                    <Badge appearance={'outline'} size={badgeSize} key={"cookieCount"} id={"cookieCount"}
                           style={{color: cookieColor}} icon={<Cookies20Filled />}>{props.cookiesCount}</Badge>
                </Tooltip>
            );
        }
        if (props.passwordsCount > 0) {
            items.push(
                <Tooltip content={'Passwords'} relationship={"label"} withArrow>
                    <Badge appearance={'outline'} size={badgeSize} key={"passwordsCount"} id={"passwordsCount"}
                           style={{color: passwordColor}} icon={<KeyMultiple20Filled />}>{props.passwordsCount}</Badge>
                </Tooltip>
            );
        }

        if (props.messengersCount > 0) {
            items.push(
                <Tooltip content={'Messengers'} relationship={"label"} withArrow>
                    <Badge appearance={'outline'} size={badgeSize} key={"messengersCount"} id={"messengersCount"}
                           style={{color: messengerColor}} icon={<Chat20Filled />}>{props.messengersCount}</Badge>
                </Tooltip>
            );
        }

        if (props.tags && props.tags.length > 0) {
            props.tags.forEach((tag) => { // Changed from map to forEach
                if (!tag.sources.includes(SourceType.Files)) return;

                items.push(
                    <Badge size={badgeSize} className={styles.badgeSpacing} id={uuidv4()} key={uuidv4()}
                           style={{backgroundColor: tag.color}}>{tag.name}</Badge>
                );
            });
        }

        return items;
    }

    /**
     * Переменная хранящая все элементы Summary, если они сгенерированы
     */
    const itemsToDisplay = generateItems(props);

    if (itemsToDisplay && itemsToDisplay.length > 0) {
        return (
            <Stack tokens={{childrenGap: '4px', padding: '10px'}} horizontal wrap>
                {itemsToDisplay.map((el) => el)}
            </Stack>
        );
    }

    // Если нет никаких данных для отображения в "Summary",
    // Возвращаем "пустой" компонент
    return <></>;
}

/**
 * Имя отображаемое при отладке
 */
LogSummary.displayName = "LogSummary";

export default LogSummary;
