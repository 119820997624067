import {makeAutoObservable} from "mobx";
import {TagDto, TagFilteringDto} from "../models/common/tags";
import {services} from "../services";
import {TagsPageDto} from "../models/common/tags/TagsPageDto";

/**
 * Хранилище данных для тегов
 */
export class TagsStore {
    /**
     * Конструктор хранилища тегов
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Общее количество тегов
     * @private
     */
    private _totalItems: number = 0;

    /**
     * Получить общее количество тегов
     */
    public get totalItems() {
        return this._totalItems;
    }

    /**
     * Количество отмеченных тегов
     * @private
     */
    private _selectedTags: number[] = [];

    public get selectedTags() {
        return this._selectedTags;
    }

    public set selectedTags(value: number[]) {
        this._selectedTags = value;
    }

    /**
     * Текущие теги
     * @private
     */
    private _tags: TagDto[] = [];

    /**
     * Получить текущие теги
     */
    public get tags() {
        return this._tags;
    }

    private _params: TagFilteringDto = {
        keyword: '',
        take: 50,
        offset: 0
    }

    public get params() {
        return this._params;
    }

    public set params(value: TagFilteringDto) {
        this._params = value;
    }

    public updateData(tagsPage: TagsPageDto) {
        this._tags = tagsPage.tags;
        this._totalItems = tagsPage.total
    }

    public async fetchData(resetPage: boolean) {
        if (resetPage) {
            this._params.take = 50
            this._params.offset = 0
        }

        const tagsPage = await services.tagsService.getTagsFilterInfo(this._params)
        this.updateData(tagsPage)
    }
}
