import Log from "../models/common/logs/Log";
import {makeAutoObservable} from "mobx";
import {LogsRequestDto} from "../models/common/logs/LogRequestDto";
import {LogPageDto} from "../models/common/logs/LogPageDto";
import {services} from "../services";

/**
 * Хранилище списка логов
 */
export class BinLogsStore {
    /**
     * Конструктор хранилища логов пользователя
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Общее количество логов
     * @private
     */
    private _totalItems: number = 0;

    /**
     * Получить общее количество логов
     */
    public get totalItems() {
        return this._totalItems;
    }

    /**
     * Логи
     * @private
     */
    private _logs: Log[] = [];

    /**
     * Получить список логов
     */
    public get logs() {
        return this._logs;
    }

    /**
     * Удалить элемент списка
     * @param logId Идентификатор лога
     */
    removeItemById(logId: number) {
        this._logs = this._logs.filter(item => item.id !== logId);
    }

    /**
     * Сохранить список логов
     * @param value
     */
    public set logs(value: Log[]) {
        this._logs = value;
    }

    /**
     * Параметры для запроса логов
     * @private
     */
    private _params: LogsRequestDto = {
        take: 50,
        offset: 0
    }

    /**
     * Получить фильтры
     */
    public get params() {
        return this._params;
    }

    public set params(value: LogsRequestDto) {
        this._params = value;
    }

    /**
     * ООбновление переменных новыми данными после запроса
     * @param model
     */
    public updateData(model: LogPageDto) {
        this._logs = model.logList;
        this._totalItems = model.total;
    }

    public async fetchData(resetPage: boolean) {
        if (resetPage) {
            this._params.take = 50;
            this._params.offset = 0;
        }

        const logPage = await services.binService.getDeleteList(this._params)
        this.updateData(logPage)
    }
}
