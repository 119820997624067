import React, {useCallback, useMemo, useState} from "react";
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerHeaderTitle,
    Field,
    Input,
    Switch
} from "@fluentui/react-components";
import {DatePicker} from "@fluentui/react-datepicker-compat";
import useStyle from './LogFiltersStyle';
import MultipleTagPicker from "../../TagPicker/TagPicker";
import {useStore} from "../../../hooks";
import {Dismiss24Regular, FilterDismiss20Regular, Search20Regular} from "@fluentui/react-icons";
import {observer} from "mobx-react-lite";
import {useToast} from "../../../utils/ToastProvider";
import InputMask from 'react-input-mask';

/**
 * Компонент фильтрации логов
 * @constructor
 */
const LogFilters: React.FC = () => {
    const style = useStyle();
    const store = useStore();
    const {showToast} = useToast();

    /**
     * Форматирование даты/времени в строку HH:MM
     */
    const formatTime = useCallback((time: Date | null): string => {
        if (!time) return '';
        const hours = time.getHours().toString().padStart(2, '0');
        const minutes = time.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }, []);

    const [startTime, setStartTime] = useState<string>(formatTime(store.logsStore.params.startDate ?? null))
    const [endTime, setEndTime] = useState<string>(formatTime(store.logsStore.params.endDate ?? null))

    /**
     * Парсинг строки в формате HH:MM в числа
     * @param time Время в формате HH:MM
     */
    const parseTime = useCallback((time: string): {hours: number, min: number} => {
        const [hh, mm] = time.split(':');
        const hours = parseInt(hh, 10);
        const min = parseInt(mm, 10);

        return {hours, min}
    }, [])

    /**
     * Валидация строки поля времени
     * @param time Время в формате HH:MM
     */
    const validateTime = useCallback((time: string) => {
        const {hours, min} = parseTime(time)

        if (Number.isNaN(hours) || Number.isNaN(min)) return false;
        if (hours < 0 || hours > 23) return false;
        if (min < 0 || min > 59) return false;

        return true;
    }, [parseTime])

    const startTimeIsValid = useMemo(() => startTime === '' || validateTime(startTime), [validateTime, startTime])
    const endTimeIsValid = useMemo(() => endTime === '' || validateTime(endTime), [validateTime, endTime])

    /**
     * Обработчик нажатия кнопки "Поиск"
     */
    const searchClickHandler = useCallback(async () => {
        try {
            store.logsStore.params.take = 50
            store.logsStore.params.offset = 0
            await store.logsStore.fetchData()
            store.logsStore.isFilterOpen = false;
            store.logsStore.isFilterApplied = true;
        } catch (error: any) {
            showToast("Error", error.message, "error");
        }
    }, [store.logsStore, showToast]);

    /**
     * Обработчик изменения стартовой даты
     */
    const handleStartDateChange = useCallback((date: Date | undefined | null) => {
        if (!date) return;

        if (!store.logsStore.params.startDate) {
            store.logsStore.params.startDate = new Date()
            store.logsStore.params.startDate.setHours(0, 0)
        }

        store.logsStore.params.startDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate())
    }, [store.logsStore]);

    /**
     * Обработчик изменения конечной даты
     */
    const handleEndDateChange = useCallback((date: Date | undefined | null) => {
        if (!date) return;

        if (!store.logsStore.params.endDate) {
            store.logsStore.params.endDate = new Date()
            store.logsStore.params.endDate.setHours(0, 0)
        }

        store.logsStore.params.endDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate())
    }, [store.logsStore]);

    /**
     * Обработчик изменения начального времени
     * @param value
     */
    const onChangeStartTime = useCallback((value: string) => {
        setStartTime(value)

        if (validateTime(value)) {
            if (!store.logsStore.params.startDate) store.logsStore.params.startDate = new Date()

            const parsedTime = parseTime(value)

            store.logsStore.params.startDate.setHours(parsedTime.hours, parsedTime.min)
        } else {
            store.logsStore.params.startDate?.setHours(0, 0)
        }
    }, [validateTime, parseTime, store.logsStore]);

    /**
     * Обработчик изменения времени окончания
     * @param value
     */
    const onChangeEndTime = useCallback((value: string) => {
        setEndTime(value)

        if (validateTime(value)) {
            if (!store.logsStore.params.endDate) store.logsStore.params.endDate = new Date()

            const parsedTime = parseTime(value)

            store.logsStore.params.endDate.setHours(parsedTime.hours, parsedTime.min)
        } else {
            store.logsStore.params.endDate?.setHours(0, 0)
        }
    }, [validateTime, parseTime, store.logsStore]);

    /**
     * Обработчик кнопки сброса фильтров
     */
    const handleClearFilters = useCallback(async () => {
        store.logsStore.setDefaultParams();
        setStartTime('')
        setEndTime('')
        await searchClickHandler();
        store.logsStore.isFilterApplied = false;
    }, [store.logsStore, searchClickHandler]);

    return (
        <Drawer
            size={"medium"}
            type={"overlay"}
            separator
            open={store.logsStore.isFilterOpen}
            position={"end"}
        >
            <DrawerHeader style={{padding: "0.8em", scrollbarWidth: "thin"}}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular/>}
                            onClick={() => store.logsStore.isFilterOpen = false}
                        />
                    }
                >
                    Filters
                </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody>
                <div className={style.dateTimeItemContainer}>
                    <Field label="Select a start date">
                        <DatePicker onSelectDate={handleStartDateChange}
                                    value={store.logsStore.params.startDate}
                                    placeholder="Select a start date..."
                                    showGoToToday={true}

                        />
                    </Field>
                    <Field label="Select a start time" validationState={startTimeIsValid ? 'none' : 'error'}>
                        <InputMask
                            mask="99:99"
                            value={startTime}
                            onChange={(e) => onChangeStartTime(e.target.value)}
                            alwaysShowMask={true}
                            maskChar={'0'}
                        >
                            {(inputProps) => <Input ref={inputProps.ref} {...inputProps} type="text" />}
                        </InputMask>
                    </Field>
                </div>
                <div className={style.dateTimeItemContainer}>
                    <Field label="Select a end date">
                        <DatePicker onSelectDate={handleEndDateChange}
                                    value={store.logsStore.params.endDate}
                                    placeholder="Select a end date..."
                                    showGoToToday={true}

                        />
                    </Field>
                    <Field label="Select a end time" validationState={endTimeIsValid ? 'none' : 'error'}>
                        <InputMask
                            mask="99:99"
                            value={endTime}
                            onChange={(e) => onChangeEndTime(e.target.value)}
                            alwaysShowMask={true}
                            maskChar={'0'}
                        >
                            {(inputProps) => <Input ref={inputProps.ref} {...inputProps} type="text" />}
                        </InputMask>
                    </Field>
                </div>
                <Field label="Log ID">
                    <div className={style.logRangeSelectContainer}>
                        <Input onChange={(_ev, data) => {
                            store.logsStore.params.startId = Number(data.value);
                        }}
                               min={0}
                               type={"number"}
                               placeholder={'Start ID'}
                               value={(store.logsStore.params.startId) ? store.logsStore.params.startId.toString() : ''}
                        />
                        <Input onChange={(_ev, data) => {
                            store.logsStore.params.endId = Number(data.value);
                        }}
                               type={"number"}
                               placeholder={'End ID'}
                               value={(store.logsStore.params.endId) ? store.logsStore.params.endId.toString() : ''}
                        />
                    </div>
                </Field>
                <Field label="IP">
                    <Input type={"text"}
                           placeholder={"IP"}
                           onChange={(_ev, data) => {
                               store.logsStore.params.ipAddress = data.value;
                           }}
                           value={store.logsStore.params.ipAddress}
                    />
                </Field>
                <Field label="Cookie keyword">
                    <Input type={"text"}
                           onChange={(_ev, data) => {
                               store.logsStore.params.cookieKeyword = data.value;
                           }}
                           value={store.logsStore.params.cookieKeyword}
                    />
                </Field>
                <Field label="Password keyword">
                    <Input type={"text"}
                           onChange={(_ev, data) => {
                               store.logsStore.params.passwordKeyword = data.value;
                           }}
                           value={store.logsStore.params.passwordKeyword}
                    />
                </Field>
                <MultipleTagPicker labelText={"By Tag"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.tagsList = values;
                                   }}
                                   options={store.logsStore.options.tagOptions}
                                   selected={store.logsStore.params.tagsList ?? []}
                />
                <MultipleTagPicker labelText={"By Country"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.countryList = values;
                                   }}
                                   options={store.logsStore.options.countryOptions}
                                   selected={store.logsStore.params.countryList ?? []}
                />
                <MultipleTagPicker labelText={"By Wallet"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.walletsList = values;
                                   }}
                                   options={store.logsStore.options.walletNames}
                                   selected={store.logsStore.params.walletsList ?? []}
                />
                <MultipleTagPicker labelText={"By ext-Wallet"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.walletExtensionsList = values;
                                   }}
                                   options={store.logsStore.options.extWalletNames}
                                   selected={store.logsStore.params.walletExtensionsList ?? []}
                />
                <MultipleTagPicker labelText={"By PM/2Fa ext"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.pm2FaExtensionsList = values;
                                   }}
                                   options={store.logsStore.options.pmExtNames}
                                   selected={store.logsStore.params.pm2FaExtensionsList ?? []}
                />
                <MultipleTagPicker labelText={"By build"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.buildTagList = values;
                                   }}
                                   options={store.logsStore.options.buildTags}
                                   selected={store.logsStore.params.buildTagList ?? []}
                />
                <MultipleTagPicker labelText={"By partner"}
                                   onChange={(values: string[]) => {
                                       store.logsStore.params.partnerList = values;
                                   }} options={store.logsStore.options.referralUsers.map(x => x.username)}
                                   selected={store.logsStore.params.partnerList ?? []}
                />

                <Field label={"Required"}>
                    <Switch label={"Wallets"}
                            checked={store.logsStore.params.byWallets}
                            onChange={(_ev, data) => {
                                store.logsStore.params.byWallets = data.checked
                            }}
                    />
                    <Switch label={"Ext-wallets"}
                            checked={store.logsStore.params.byWalletExtensions}
                            onChange={(_ev, data) => {
                                store.logsStore.params.byWalletExtensions = data.checked
                            }}
                    />
                    <Switch label={"Soft"}
                            checked={store.logsStore.params.bySoft}
                            onChange={(_ev, data) => {
                                store.logsStore.params.bySoft = data.checked
                            }}
                    />
                    <Switch label={"Messenger"}
                            checked={store.logsStore.params.byMessenger}
                            onChange={(_ev, data) => {
                                store.logsStore.params.byMessenger = data.checked
                            }}
                    />
                    <Switch label={"CC"}
                            checked={store.logsStore.params.byCreditCard}
                            onChange={(_ev, data) => {
                                store.logsStore.params.byCreditCard = data.checked
                            }}
                    />
                    <Switch label={"Not downloaded"}
                            checked={store.logsStore.params.notDownloaded}
                            onChange={(_ev, data) => {
                                store.logsStore.params.notDownloaded = data.checked
                            }}
                    />
                    <Switch label={"With notes"}
                            checked={store.logsStore.params.byNotes}
                            onChange={(_ev, data) => {
                                store.logsStore.params.byNotes = data.checked
                            }}
                    />
                </Field>
            </DrawerBody>
            <DrawerFooter style={{padding: "1em"}}>
                <Button appearance={"primary"}
                        icon={<Search20Regular/>}
                        onClick={searchClickHandler}>
                    Search
                </Button>
                <Button appearance={"secondary"}
                        icon={<FilterDismiss20Regular/>}
                        onClick={handleClearFilters}>
                    Clear
                </Button>
            </DrawerFooter>
        </Drawer>
    );
}

/**
 * Имя отображаемое при отладке
 */
LogFilters.displayName = "LogFilters";

export default observer(LogFilters);
