import React from "react";
import {Layout} from "../../../containers/Layout";
import {ErrorsList} from "../../../components/ErrorsList";
import ErrorsTools from "./ErrorsTools";

/**
 * Страница с ошибками
 * @constructor
 */
const ErrorsPage: React.FC = () => {
    return (
        <Layout pageTittle={"Errors Page"} anotherBackground={true}>
            <ErrorsTools />
            <ErrorsList />
        </Layout>
    )
}

/**
 * Имя отображаемое при отладке
 */
ErrorsPage.displayName = "ErrorsPage"
export default ErrorsPage
