import React, {useEffect, useState} from "react";
import {ToolsContainer} from "../../../containers/ToolsContainer";
import {Button, Input, Select} from "@fluentui/react-components";
import {LoadingButton} from "../../../components/ButtonWithLoadingState";
import {LoadingState} from "../../../models";
import {useServices, useStore} from "../../../hooks";
import {COOKIE_LIST_URL} from "../../../shared/constUrl";
import {useSearchParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {ArrowDownload20Regular} from "@fluentui/react-icons";
import {ExtendedPagination} from "../../../components/ExtendedPagination";

/**
 * Инструменты для управления cookies
 * @constructor
 */
const CookiesTools: React.FC = () => {
    const services = useServices();
    const [searchParams] = useSearchParams();
    const store = useStore();
    const [loadingState, setLoadingState] = useState<LoadingState>("initial");
    const [keyword, setKeyword] = useState<string>();
    const [browser, setBrowser] = useState<string>('');
    const [options, setOptions] = useState<string[]>([]);

    /**
     * Обработчик ввода текста
     * @param event
     */
    const handleInputChange = (event: any) => {
        setKeyword(event.target.value); // Сохраняем введенное значение в состояние
    };

    /**
     * Загрузка списка Cookies и опций для фильтрации
     */
    useEffect(() => {
        (async() => {
            const options = await services.cookiesService.getOptions();
            setOptions(options);
            setBrowser(options[0]);
        })()
    }, [services.cookiesService]);

    /**
     * логика скачивания файла
     */
    const handleDownload = async () => {
        const logId = searchParams.get('botId') || '0';
        const params = new URLSearchParams({
            botId: logId,
            browserName: browser,
            keyword: keyword ?? ''
        });

        const urlWithParams = `${COOKIE_LIST_URL}/download?${params.toString()}`;
        await services.fileDownloadService.downloadFile(urlWithParams);
    };

    /**
     * Обработчик нажатия кнопки "Поиск"
     */
    const searchClickHandler = async () => {
        try {
            setLoadingState("loading");
            await store.cookiesStore.fetchData();
            setLoadingState("loaded");
        } catch (error: any) {
            setLoadingState("error");
        }
    };

    /**
     * Обработчик выбора элемента
     */
    const handleSelectChange = (event: any) => {
        const value = event.target.value;
        setBrowser(value); // Сохраняем выбранное значение в состояние
    };

    return (
        <ToolsContainer>
            {/* Контейнер для всей строки */}
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                {/* Левый блок (фильтры и кнопки) */}
                <div style={{display: "flex", alignItems: "center", gap: "1em"}}>
                    {/* Обёртка для браузера с лейблом */}
                    <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
                        <label>Browser name:</label>
                        <Select onChange={handleSelectChange}>
                            {options.map((val) => (
                                <option key={val} value={val}>{val}</option>
                            ))}
                        </Select>
                    </div>

                    {/* Обёртка для ключевого слова с лейблом */}
                    <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
                        <label>Keyword:</label>
                        <Input onChange={handleInputChange}></Input>
                    </div>

                    <LoadingButton
                        appearance={"primary"}
                        enabled={true}
                        buttonText={"Search"}
                        state={loadingState}
                        onClick={searchClickHandler}
                    />
                    <Button onClick={() => handleDownload()}>
                        <ArrowDownload20Regular/> Download
                    </Button>
                </div>
            </div>
            <ExtendedPagination onChangePage={(paginationParams) => store.cookiesStore.params = {...store.cookiesStore.params, ...paginationParams}}
                                isLoading={store.loaderStore.loading}
                                total={store.cookiesStore.totalItems}
                                take={store.cookiesStore.params.take}
                                offset={store.cookiesStore.params.offset}
            />
        </ToolsContainer>
    );
}

CookiesTools.displayName = "CookiesTools";

export default observer(CookiesTools);
