import { makeStyles, shorthands } from '@fluentui/react-components';

const UseStyle = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        padding: '20px',
    },
    infoCard: {
        width: '100%',
        maxWidth: '800px',
        padding: '20px',
        ...shorthands.borderRadius('8px'),
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        ...shorthands.border('1px', 'solid', '#ddd'),
    },
    cardGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gap: '20px',
        width: '100%',
        maxWidth: '1000px',
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        color: 'white',
    },
    cardIcon: {
        fontSize: '24px',
    },
});

export default UseStyle;
