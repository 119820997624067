import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow
} from "@fluentui/react-components";
import {ArrayTableProps} from "./ArrayTableProps";
import {useStyles} from './ArrayTableStyles'

/**
 * Компонент отображения массивов в виде таблицы
 * @param props
 * @constructor
 */
export const ArrayTable: React.FC<ArrayTableProps> = (props) => {
    const styles = useStyles();

    return (
        <Table {...props} className={styles.responsiveTable}>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>#</TableHeaderCell>
                    <TableHeaderCell>{props.headerName}</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {props.data.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            <TableCellLayout>
                                {index + 1}
                            </TableCellLayout>
                        </TableCell>
                        <TableCell>
                            <TableCellLayout>
                                {item}
                            </TableCellLayout>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
