import {makeAutoObservable} from "mobx";
import {DataError} from "../../models/admin/dataErrors/dataError";
import {services} from "../../services";
import {DataErrorsFiltering} from "../../models/admin/dataErrors/dataErrorsFiltering";
import {DataErrorsViewDto} from "../../models/admin/dataErrors/dataErrorsViewDto";

/**
 * Хранилище для страницы Data Errors
 */
export class DataErrorsStore {
    /**
     * Конструктор хранилища Data Errors
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Общее количество ошибок
     * @private
     */
    private _totalItems: number = 0;

    /**
     * Получить общее количество ошибок
     */
    public get totalItems() {
        return this._totalItems;
    }

    public set totalItems(value: number) {
        this._totalItems = value;
    }

    /**
     * Список ошибок
     * @private
     */
    private _errors: DataError[] = [];

    /**
     * Получить список ошибок
     */
    public get errors() {
        return this._errors;
    }

    /**
     * Установить список ошибок
     * @param value Список ошибок
     */
    public set errors(value: DataError[]) {
        this._errors = value;
    }

    /**
     * Параметры фильтрации
     * @private
     */
    private _params: DataErrorsFiltering = {
        take: 50,
        offset: 0
    }

    /**
     * Получить параметры фильтрации
     */
    public get params() {
        return this._params;
    }

    /**
     * Установить параметры фильтрации
     */
    public set params(value: DataErrorsFiltering) {
        this._params = value;
    }

    /**
     * Выделенные ошибки в таблице
     * @private
     */
    private _selectedErrors: number[] = [];

    /**
     * Получить выделенные ошибки
     */
    public get selectedErrors() {
        return this._selectedErrors;
    }

    /**
     * Установить выделенные ошибки
     * @param value
     */
    public set selectedErrors(value: number[]) {
        this._selectedErrors = value;
    }

    /**
     * Обновить данные
     * @param model
     */
    public updateData(model: DataErrorsViewDto) {
        this._errors = model.errors
        this._totalItems = model.total;
        this._selectedErrors = []
    }

    public async fetchData(pageReset: boolean) {
        if (pageReset) {
            this._params.take = 50;
            this._params.offset = 0;
        }

        const errorPage = await services.adminDataErrorService.getList(this._params)
        this.updateData(errorPage)
    }
}
