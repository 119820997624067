import {AxiosService} from "./axios";
import {CookieRequestDto, CookiesPageDto} from "../models/common";
import {UrlParamsSerializer} from "../utils";

/**
 * Сервис работы с Cookies
 */
export class CookiesService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить список Cookies
     */
    async getList(data: CookieRequestDto): Promise<CookiesPageDto> {
        try {
            const response = await this.axios.get(`/cookies?${UrlParamsSerializer(data)}`)
            return Promise.resolve(response.data as CookiesPageDto);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Получить список браузеров доступных пользователю
     * @returns Список браузеров для фильтрации
     */
    async getOptions(): Promise<string[]> {
        try {
            const response = await this.axios.get("/cookies/get-options");
            return Promise.resolve(response.data as string[]);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
