import React, {useCallback, useState} from "react";
import {ExtendedPagination} from "../../../components/ExtendedPagination";
import {ToolsContainer} from "../../../containers/ToolsContainer";
import {useServices, useStore} from "../../../hooks";
import {observer} from "mobx-react-lite";
import {Button, Input, Select, tokens} from "@fluentui/react-components";
import {Stack} from "@fluentui/react";
import {ArrowDownload20Regular, BinRecycle20Regular, Search20Regular} from "@fluentui/react-icons";
import {useToast} from "../../../utils/ToastProvider";
import {ApiError} from "../../../models";
import {GetMessageByCode} from "../../../utils";
import {ConfirmationDialog} from "../../../components/ConfirmationDialog/ConfirmationDialog";

/**
 * Toolbar для страницы паролей
 * @constructor
 */
const PasswordsTools: React.FC = () => {
    const store = useStore();
    const services = useServices();
    const {showToast} = useToast();

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = useCallback((error: ApiError) => {
        showToast('Error!', GetMessageByCode(error.code), "error");
    }, [showToast]);

    const [browserName, setBrowserName] = useState<string>('')
    const [keyword, setKeyword] = useState<string>('')

    /**
     * Коллбэк для поиска паролей
     */
    const onSearch = useCallback(async() => {
        try {
            store.passwordsStore.params.browserName = browserName;
            store.passwordsStore.params.keyword = keyword;

            await store.passwordsStore.fetchData(true)
        } catch (error: any) {
            showErrorNotify(error)
        }
    }, [store.passwordsStore, browserName, keyword, showErrorNotify])

    /**
     * Коллбэк для скачивания паролей
     */
    const onDownload = useCallback(async() => {
        try {
            await services.passwordService.downloadPasswords(store.passwordsStore.params)
        } catch (error: any) {
            showErrorNotify(error)
        }
    }, [services.passwordService, store.passwordsStore.params, showErrorNotify])

    /**
     * Коллбэк для удаления паролей по фильтрам
     */
    const onDelete = useCallback(async() => {
        try {
            await services.passwordService.deleteManyPasswords(store.passwordsStore.params)
            await store.passwordsStore.fetchData(true)
        } catch (error: any) {
            showErrorNotify(error)
        }
    }, [services.passwordService, showErrorNotify, store.passwordsStore])

    return (
        <ToolsContainer>
            <Stack tokens={{childrenGap: '1em'}} verticalAlign={"center"} horizontalAlign={"space-between"} horizontal wrap>
                <Stack tokens={{childrenGap: '0.5em'}} verticalAlign={"center"} horizontal>
                    <label>Browser name:</label>
                    <Select disabled={store.loaderStore.loading} value={browserName} onChange={(_, data) => {setBrowserName(data.value)}}>
                        <option value={''}>All</option>
                        {store.passwordsStore.browserOptions.map((value) => (
                            <option key={value}>{value}</option>
                        ))}
                    </Select>
                </Stack>

                <Stack tokens={{childrenGap: '0.5em'}} verticalAlign={"center"} horizontal>
                    <label>Keyword:</label>
                    <Input value={keyword} onChange={(e) => setKeyword(e.target.value)} disabled={store.loaderStore.loading} />
                </Stack>

                <Stack tokens={{childrenGap: '0.5em'}} verticalAlign={"center"} horizontal>
                    <Button icon={<Search20Regular/>} onClick={onSearch} disabled={store.loaderStore.loading}>Search</Button>

                    <Button onClick={onDownload} appearance={"primary"} icon={<ArrowDownload20Regular/>} disabled={store.loaderStore.loading || store.passwordsStore.totalItems === 0}>Download</Button>

                    <ConfirmationDialog
                        triggerButton={<Button icon={<BinRecycle20Regular />} disabled={store.loaderStore.loading || store.passwordsStore.totalItems === 0} style={{color: tokens.colorPaletteDarkOrangeForeground1}}>Delete</Button>}
                        title={'Delete passwords?'}
                        content={'If you have specified filters, passwords matching the filters will be deleted. Check the specified filters, click the search button to confirm the filters. Deleted passwords cannot be restored!'}
                        confirmationText={'I understand, delete.'}
                        onSubmit={onDelete}
                    />
                </Stack>
            </Stack>

            <ExtendedPagination total={store.passwordsStore.totalItems}
                                offset={store.passwordsStore.params.offset}
                                take={store.passwordsStore.params.take}
                                onChangePage={(paginationParams) => store.passwordsStore.params = {...store.passwordsStore.params, ...paginationParams}}
                                isLoading={store.loaderStore.loading}
            />
        </ToolsContainer>
    )
}

export default observer(PasswordsTools)
