import React, {useState} from "react";
import ToolsContainer from "../../../containers/ToolsContainer/ToolsContainer";
import {Button, tokens} from "@fluentui/react-components";
import {ArrowClockwiseRegular, DeleteRegular} from "@fluentui/react-icons";
import {useServices, useStore} from "../../../hooks";
import ActionDialog from "../../../components/ActionDialog";
import {LoadingButton} from "../../../components/ButtonWithLoadingState";
import {observer} from "mobx-react-lite";
import {ExtendedPagination} from "../../../components/ExtendedPagination";

/**
 * Инструменты работы с Корзиной
 * @constructor
 */
const BinTools: React.FC = () => {
    const store = useStore();
    const services = useServices();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    /**
     * Восстановить все логи
     */
    const recoverAll = async () => {
        try {
            await services.binService.recoverAll();
            await store.binLogsStore.fetchData(true);
        } catch (error: any) {
            console.error(error);
        }
    };

    /**
     * Удалить все логи
     */
    const deleteAll = async () => {
        try {
            await services.binService.deleteAll();
            await store.binLogsStore.fetchData(true);
        } catch (error: any) {
            console.error(error);
        } finally {
            setIsDialogOpen(false);  // Закрыть диалог после удаления
        }
    };

    return (
        <ToolsContainer>
            {/* Левая сторона: кнопки "Recovery All" и "Delete All" */}
            <div style={{display: "flex", alignItems: "center", gap: "1em"}}>
                <LoadingButton appearance={"primary"}
                               icon={<ArrowClockwiseRegular/>}
                               onClick={recoverAll}
                               enabled={true}
                               buttonText={"Recover All"}/>

                <ActionDialog
                    title={"Warning: This action cannot be undone!"}
                    isOpen={isDialogOpen}
                    actions={
                        <>
                            <Button appearance="secondary" onClick={() => setIsDialogOpen(false)}>
                                Cancel
                            </Button>
                            <Button appearance="primary"
                                    style={{background: tokens.colorPaletteDarkOrangeForeground1}}
                                    onClick={deleteAll}>
                                Delete All
                            </Button>
                        </>}
                    trigger={
                        <Button
                            appearance={"primary"}
                            icon={<DeleteRegular/>}
                            style={{background: tokens.colorPaletteDarkOrangeForeground1}}
                            onClick={() => setIsDialogOpen(true)}>  {/* Открыть диалог при клике */}
                            Delete All
                        </Button>}
                    content={"Are you sure you want to delete all the items? This action cannot be undone."}
                />
            </div>

            <ExtendedPagination total={store.binLogsStore.totalItems}
                                take={store.binLogsStore.params.take}
                                offset={store.binLogsStore.params.offset}
                                onChangePage={(paginationParams) => store.binLogsStore.params = {...store.binLogsStore.params, ...paginationParams}}
                                isLoading={store.loaderStore.loading}
            />
        </ToolsContainer>
    );
};

/**
 * Имя отображаемое при отладке
 */
BinTools.displayName = "BinTools";

export default observer(BinTools);
