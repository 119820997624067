import {AxiosService} from "./axios";
import {PasswordsFiltering} from "../models/common";
import {PasswordsPage} from "../models/common";
import {UrlParamsSerializer} from "../utils";
import {services} from "./index";

export class PasswordService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор сервиса
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * получить пароли
     */
    async getPasswords(filter: PasswordsFiltering): Promise<PasswordsPage> {
        try {
            const resp = await this.axios.post<PasswordsPage>("/passwords/filtering/", filter);
            return Promise.resolve(resp.data);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * удалить пароль
     */
    async deletePassword(passwordId: number): Promise<void> {
        try {
            await this.axios.post(`/passwords/delete/${passwordId}`);
            return Promise.resolve();
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Скачать пароли по фильтрам
     * @param filter
     */
    async downloadPasswords(filter: PasswordsFiltering) {
        try {
            const {take, offset, ...filteredData} = filter;
            await services.fileDownloadService.downloadFile(`/passwords/download?${UrlParamsSerializer(filteredData)}`)
            return Promise.resolve()
        } catch (error: any) {
            return Promise.reject(error)
        }
    }

    /**
     * Удалить пароли по фильтрам
     * @param filter
     */
    async deleteManyPasswords(filter: PasswordsFiltering) {
        try {
            const {take, offset, ...filteredData} = filter;
            await this.axios.post('/passwords/delete-many', filteredData)
            return Promise.resolve()
        } catch (error: any) {
            return Promise.reject(error)
        }
    }
}
