import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from "@fluentui/react-components";
import {NoItems} from "../NoItems";
import React, {useEffect} from "react";
import {useStore} from "../../hooks";
import {LogOpenDetailsButton} from "../LogOpenDetailsButton";
import {observer} from "mobx-react-lite";
import {useSearchParams} from "react-router-dom";

// Перенос columns выше для улучшения порядка
const columns = [
    {columnKey: "browser", label: "Browser"},
    {columnKey: "data", label: "Data"},
    {columnKey: "actions", label: "Actions"},
];

/**
 * Компонент отображения списка Cookies
 */
const CookieList: React.FC = () => {
    const [searchParams] = useSearchParams()
    const store = useStore();

    /**
     * Загрузка списка Cookies и опций для фильтрации
     */
    useEffect(() => {
        (async() => {
            store.cookiesStore.params.botId = Number(searchParams.get('botId')) || 0;

            await store.cookiesStore.fetchData()
        })()
    }, [store.cookiesStore.params, searchParams, store.cookiesStore]);

    return (
        <Card style={{width: '100%', padding: "0.3em"}}>
            <Table
                style={{width: '100%'}}
                as={"table"}
            >
                <TableHeader as={"thead"}>
                    <TableRow as="tr" style={{
                        display: 'grid',
                        gridTemplateColumns: '100px minmax(200px, 1fr) 100px', // Используем minmax для адаптации
                        gap: '10px',
                        alignItems: 'center'
                    }}>
                        {columns.map((column) => (
                            <TableHeaderCell key={column.columnKey} as={"th"} style={{
                                display: 'flex',
                                justifyContent: 'center', // Горизонтальная центровка
                                alignItems: 'center',     // Вертикальная центровка
                            }}>
                                {column.label}
                            </TableHeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody as={"tbody"}>
                    {store.cookiesStore.totalItems === 0
                        ? (
                            <TableRow as={"tr"} style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr',
                                height: 'auto',
                                alignItems: 'center' // Вертикальная центровка для пустых строк
                            }}>
                                <TableCell as={"td"} style={{
                                    display: 'flex',
                                    justifyContent: 'center', // Горизонтальная центровка
                                    alignItems: 'center',
                                    padding: '2em'// Вертикальная центровка
                                }}>
                                    <NoItems/>
                                </TableCell>
                            </TableRow>
                        )
                        : store.cookiesStore.cookies.map((item) => (
                            <TableRow as={"tr"} key={item.id} style={{
                                display: 'grid',
                                gridTemplateColumns: '100px minmax(200px, 1fr) 100px',
                                gap: '5px',
                                height: 'auto',
                                alignItems: 'center' // Центровка контента по вертикали
                            }}>
                                <TableCell as={"td"} style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <TableCellLayout>
                                        {item.browserName}
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell as={"td"} style={{
                                    display: 'block',
                                    whiteSpace: 'pretty', // Отключаем перенос текста
                                    wordBreak: 'break-word',
                                    height: 'auto',
                                }}>
                                    <TableCellLayout>
                                        {item.cookieString}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell as={"td"} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <TableCellLayout>
                                        <LogOpenDetailsButton logId={item.botId}/>
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Card>
    );
}

/**
 * Имя отображаемое при отладке
 */
CookieList.displayName = "CookieList";

export default observer(CookieList);
