import React from "react";
import {SelectedCountProp} from "./SelectedCountProp";
import {Badge} from "@fluentui/react-components";

/**
 * Компонент для отображения количества выделенных элементов
 * @constructor
 */
const SelectedCount: React.FC<SelectedCountProp> = (props) => {
    return props.count > 0 ? <Badge size={"large"} appearance={"outline"} style={{margin: '.4em'}}>Selected: {props.count}</Badge> : null
}

/**
 * Имя отображаемое при отладке
 */
SelectedCount.displayName = "SelectedCount";

export default SelectedCount;
