import {AxiosService} from "./axios";
import {LogsRequestDto} from "../models/common/logs/LogRequestDto";
import {LogPageDto} from "../models/common/logs/LogPageDto";
import {BIN_URL} from "../shared/constUrl";
import {UrlParamsSerializer} from "../utils";

/**
 * Сервис для работы с корзиной
 */
export class BinService {
    /**
     * Сервис axios
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить список удалённых логов.
     * @param options Параметры поиска
     */
    async getDeleteList(options: LogsRequestDto): Promise<LogPageDto> {
        try {
            return (await this.axios.get<LogPageDto>(`${BIN_URL}?${UrlParamsSerializer(options)}`)).data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Восстановить лог из корзины
     * @param logId Идентификатор лога
     */
    async restore(logId: number): Promise<void> {
        try {
            await this.axios.post(BIN_URL + '/restore/' + logId);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удалить лог из корзины
     * @param logId Идентификатор лога
     */
    async delete(logId: number): Promise<void> {
        try {
            await this.axios.delete(BIN_URL + '/delete/' + logId);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удалить все логи из корзины
     */
    async deleteAll(): Promise<void> {
        try {
            await this.axios.delete(BIN_URL + '/delete/all');
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Восстановить все
     */
    async recoverAll(): Promise<void> {
        try {
            await this.axios.post(BIN_URL + '/restore/all');
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
