import React, {useCallback, useEffect, useState} from "react";
import {JsonProfilePageDto} from "../../models/admin";
import {useServices} from "../../hooks";
import {useToast} from "../../utils/ToastProvider";
import {useNavigate} from "react-router-dom";
import {ADMIN_JSON_PROFILE_OPEN_URL, ADMIN_JSON_PROFILES_URL} from "../../shared/constUrl";
import {Button, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "@fluentui/react-components";
import {LoadingButton} from "../ButtonWithLoadingState";
import {ArrowDownload20Regular, Open20Regular} from "@fluentui/react-icons";
import useStyles from "./JsonProfilesListStyles";

/**
 * Список профилей пользователей
 * @constructor
 */
const JsonProfilesList: React.FC = () => {
    /**
     * Именования столбцов таблицы
     */
    const columns = [
        {columnKey: "userId", label: "User ID"},
        {columnKey: "userName", label: "Username"},
        {columnKey: "version", label: "Version"},
        {columnKey: "uploadTime", label: "Uploaded At"},
        {columnKey: "actions", label: "Actions"},
    ];

    /**
     * Значения по умолчанию
     */
    const defaults: JsonProfilePageDto = {
        profiles: []
    };

    const [profilesDto, setProfilesDto] = useState<JsonProfilePageDto>(defaults);
    const services = useServices();
    const {showToast} = useToast();
    const navigate = useNavigate();
    const styles = useStyles();

    /**
     * запрос и загрузка списка профилей
     */
    const updateJsonProfilesList = useCallback(async () => {
        try {
            const response = await services.adminJsonProfilesService.getList();
            setProfilesDto(response);
        } catch (error: any) {
            showToast("Error!", error, "error");
        }
    }, [services, showToast]);

    /**
     * Эффект загрузки профилей
     */
    useEffect(() => {
        updateJsonProfilesList().then(() => {
        });
    }, [updateJsonProfilesList]);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, userId: string) => {
        const file = event.target.files?.[0];
        if (!file) {
            alert('Выберите файл для загрузки');
            return;
        }

        const confirm = window.confirm(`Вы уверены, что хотите загрузить файл для пользователя ${userId}?`);
        if (!confirm) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file)

        try {
            await services.adminJsonProfilesService.uploadFile(formData, userId);
            alert('Файл успешно загружен');
            await updateJsonProfilesList(); // Обновляем список после успешной загрузки
        } catch (error: any) {
            showToast("Error!", error, "error");
        }
    };

    const downloadItem = useCallback(async (path: string) => {
        try {
            const urlWithParams = `${ADMIN_JSON_PROFILES_URL}/download/${path}`;
            await services.fileDownloadService.downloadFile(urlWithParams);
        } catch {
            showToast("Error", "Something went wrong...", "error");
        }
    }, [showToast, services.fileDownloadService]);


    return <div>
        <Table
            role={"table"}
            aria-label="Table with grid keyboard navigation"
            className={styles.profileTable}>
            <TableHeader>
                <TableRow>
                    {columns.map((column) => (
                        <TableHeaderCell key={column.columnKey}>
                            {column.label}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {profilesDto.profiles.map((item) => (
                    <TableRow key={item.userId}>
                        <TableCell>{item.userId}</TableCell>
                        <TableCell>{item.userName}</TableCell>
                        <TableCell>{item.version}</TableCell>
                        <TableCell>{item.uploadAt}</TableCell>
                        <TableCell>
                            <div className={styles.actionsButtonsContainer}>
                                <LoadingButton
                                    enabled={true}
                                    onClick={() => downloadItem(item.userId)}
                                    appearance="primary"
                                    icon={<ArrowDownload20Regular/>}
                                />
                                <Button icon={<Open20Regular/>} onClick={() => {
                                    navigate(ADMIN_JSON_PROFILE_OPEN_URL + '/' + item.userId);
                                }}/>
                                <input
                                    title="file"
                                    type="file"
                                    onChange={(event) => handleFileUpload(event, item.userId)}
                                />
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </div>
}

JsonProfilesList.displayName = "JsonProfilesList";
export default JsonProfilesList;
