import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    table: {
        tableLayout: 'auto'
    },
    tableCell: {
        wordBreak: 'break-all'
    },
    actionsCell: {
        whiteSpace: 'nowrap'
    }
})
