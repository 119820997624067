import {makeAutoObservable} from "mobx";
import {CookieItemDto, CookieRequestDto, CookiesPageDto} from "../models/common";
import {services} from "../services";

/**
 * Хранилище списка cookies
 */
export class CookiesStore {

    /**
     * Конструктор хранилища cookies
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Общее количество куков
     * @private
     */
    private _totalItems: number = 0;

    /**
     * Получить общее количество куков
     */
    public get totalItems() {
        return this._totalItems;
    }

    /**
     * Куки
     * @private
     */
    private _cookies: CookieItemDto[] = [];

    /**
     * Опции для поиска
     * @private
     */
    private _options: string[] = [];

    /**
     * Получить список куков
     */
    public get cookies() {
        return this._cookies;
    }

    /**
     * Сохранить список куков
     * @param value
     */
    public set cookies(value: CookieItemDto[]) {
        this._cookies = value;
    }

    /**
     * Получить список опций
     */
    public get options() {
        return this._options;
    }

    /**
     * Сохранить список опций
     * * @param value
     */
    public set options(value: string[]) {
        this._options = value;
    }

    /**
     * Параметры для запроса логов
     * @private
     */
    private _params: CookieRequestDto = {
        botId: 0,
        keyword: "",
        browserName: "",
        take: 50,
        offset: 0
    }

    /**
     * Получить параметры
     */
    public get params() {
        return this._params;
    }

    /**
     * Сохранить параметры фильтрации
     * * @param value
     */
    public set params(value: CookieRequestDto) {
        this._params = value;
    }

    /**
     * ООбновление переменных новыми данными после запроса
     * @param model
     */
    public updateData(model: CookiesPageDto) {
        this._cookies = model.cookies;
        this._totalItems = model.total;
    }

    public async fetchData() {
        const cookiePage = await services.cookiesService.getList(this._params)
        this.updateData(cookiePage)
    }
}
