import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    controlTextField: {
        display: "flex",
        alignItems: "center",
        gap: '0.5em'
    },
    controlPagination: {
        display: "flex",
        gap: "1em",
        justifyContent: "flex-end",
    },
    controlContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "0.5em",
    },
    modalBody: {
        marginTop: '10px'
    }
});
