import {makeStyles} from "@fluentui/react-components";

/**
 * Стили для компонента отображения массивов в виде таблицы
 */
export const useStyles = makeStyles({
    responsiveTable: {
        tableLayout: 'auto'
    }
})
