import {ToolsContainer} from "../../../containers/ToolsContainer";
import React, {useCallback} from "react";
import {LoadingButton} from "../../../components/ButtonWithLoadingState";
import {
    ArrowDownload20Regular,
    BinRecycle20Filled,
    BinRecycle20Regular,
    DataFunnel20Filled,
    DataFunnel20Regular
} from "@fluentui/react-icons";
import {Button, tokens} from "@fluentui/react-components";
import {useServices, useStore} from "../../../hooks";
import {SelectedCount} from "../../../components/SelectedCount";
import {observer} from "mobx-react-lite";
import {ApiError} from "../../../models";
import {GetMessageByCode} from "../../../utils";
import UseStyle from "./LogListToolsStyles";
import {useToast} from "../../../utils/ToastProvider";
import {ExtendedPagination} from "../../../components/ExtendedPagination";

const LogListTools: React.FC = () => {
    const store = useStore();
    const services = useServices();
    const styles = UseStyle();
    const {showToast} = useToast();

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = useCallback((error: ApiError) => {
        showToast('Error!', GetMessageByCode(error.code), "error");
    }, [showToast]);

    /**
     * Отображение количества выбранных элементов
     */
    const showSelectedCount = () => {
        if (store.logsStore.selectedLogs.length > 0) {
            return <SelectedCount count={store.logsStore.selectedLogs.length}/>
        }
        return;
    }

    /**
     * Обработчик нажатия кнопки удалить пустые логи
     */
    const deleteEmptyHandler = async () => {
        try {
            await services.logsService.deleteEmpty();
        } catch (e: any) {
            showErrorNotify(e);
        }
    };

    /**
     * Получаем идентификаторы логов с отмеченных элементов
     */
    const getLogIdsFromSelected = (): number[] => {
        const selectedIds = Array.from(store.logsStore.selectedLogs)
            .map(id => Number(id))
            .filter(id => !isNaN(id));
        const selectedLogs = store.logsStore.logs.filter(log => selectedIds.includes(log.id));
        return selectedLogs.map(log => log.id);
    };

    /**
     * Обработчик кнопки "Скачать"
     */
    const onDownloadButton = async () => {
        try {
            store.loaderStore.setLoading(true);

            if (store.logsStore.isFilterApplied && store.logsStore.selectedLogs.length <= 0) {
                await services.fileDownloadService.requestLogByFilter(store.logsStore.params);
                showToast("Success", "This may take a while. File requested and preparing for you", "success");
            } else {
                if (store.logsStore.selectedLogs && store.logsStore.selectedLogs.length > 0) {
                    await services.fileDownloadService.requestLogsFiles(getLogIdsFromSelected());
                } else {
                    await services.fileDownloadService.requestAllLogFiles();
                }
                showToast('Processing...', 'This may take a while. File requested and preparing for you', 'info');
            }

        } catch (error: any) {
            console.error(error);
            showErrorNotify(error);
        } finally {
            store.loaderStore.setLoading(false);
        }
    };

    /**
     * Обработчик кнопки "Удалить"
     */
    const onDeleteButton = async() => {
        try {
            store.loaderStore.setLoading(true);

            if (store.logsStore.selectedLogs.length === 0) return;

            await services.logsService.deleteMany(store.logsStore.selectedLogs)

            store.logsStore.selectedLogs = []

            await store.logsStore.fetchData()

            showToast("Success", 'The selected logs have been deleted', "success");
        } catch (error: any) {
            showErrorNotify(error);
        } finally {
            store.loaderStore.setLoading(false);
        }
    }

    return (
        <ToolsContainer>
            <div className={styles.controlContainer}>
                <LoadingButton
                    buttonText={"Download"}
                    icon=<ArrowDownload20Regular/>
                    onClick={onDownloadButton}
                    enabled={true}
                    appearance={"primary"}
                />

                <LoadingButton
                    buttonText={"Delete"}
                    style={{
                        color: tokens.colorPaletteDarkOrangeForeground1
                    }}
                    onClick={onDeleteButton}
                    enabled={!store.loaderStore.loading && store.logsStore.selectedLogs.length > 0}
                    icon={<BinRecycle20Filled/>}/>

                <LoadingButton buttonText={"Delete empty"}
                               icon=<BinRecycle20Regular/>
                               enabled={true}
                               onClick={deleteEmptyHandler}
                />
                <Button appearance={"outline"}
                        onClick={() => {
                            store.logsStore.isFilterOpen = !store.logsStore.isFilterOpen
                        }}
                        icon={(store.logsStore.isFilterApplied) ? <DataFunnel20Filled/> : <DataFunnel20Regular/>}
                >
                    Filters
                </Button>
                {showSelectedCount()}
            </div>
            <ExtendedPagination total={store.logsStore.totalItems}
                                offset={store.logsStore.params.offset}
                                take={store.logsStore.params.take}
                                onChangePage={(paginationParams) => store.logsStore.params = {...store.logsStore.params, ...paginationParams}}
                                isLoading={store.loaderStore.loading}
            />
        </ToolsContainer>
    );
}

/**
 * Имя отображаемое при отладке
 */
LogListTools.displayName = "LogListTools";

export default observer(LogListTools);
