import React, {useCallback, useEffect, useRef} from "react";
import {observer} from "mobx-react-lite";
import {
    Badge, Button,
    Card,
    Table,
    TableBody, TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow, tokens,
} from "@fluentui/react-components";
import {useServices, useStore} from "../../hooks";
import {useToast} from "../../utils/ToastProvider";
import {GetMessageByCode} from "../../utils";
import {ApiError} from "../../models";
import {NoItems} from "../NoItems";
import useStyles from './PasswordListStyles'
import {LogOpenDetailsButton} from "../LogOpenDetailsButton";
import {DeleteRegular} from "@fluentui/react-icons";
import {useSearchParams} from "react-router-dom";

const PasswordsList: React.FC = () => {
    const store = useStore();
    const services = useServices();
    const styles = useStyles();
    const {showToast} = useToast();
    const [searchParams] = useSearchParams()
    const initial = useRef(true);

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = useCallback((error: ApiError) => {
        showToast('Error!', GetMessageByCode(error.code), "error");
    }, [showToast]);

    useEffect(() => {
        (async() => {
            try {
                const botId = searchParams.get('botid')

                store.passwordsStore.params.logId = Number(botId)

                await store.passwordsStore.fetchData(initial.current)

                if (initial) initial.current = false
            } catch (error: any) {
                showErrorNotify(error)
            }
        })()
    }, [store, showErrorNotify, store.passwordsStore.params, searchParams]);

    const onDelete = useCallback(async(itemId: number) => {
        try {
            await services.passwordService.deletePassword(itemId)
            await store.passwordsStore.fetchData(false)
        } catch (error: any) {
            showErrorNotify(error)
        }
    }, [services.passwordService, store.passwordsStore, showErrorNotify])

    return (
        <Card style={{maxWidth: '100%', padding: "0.3em"}}>
            <Table size={"small"} className={styles.table}>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Browser</TableHeaderCell>
                        <TableHeaderCell>URL</TableHeaderCell>
                        <TableHeaderCell>Login</TableHeaderCell>
                        <TableHeaderCell>Password</TableHeaderCell>
                        <TableHeaderCell>Actions</TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {store.passwordsStore.totalItems === 0 ? (
                        <TableRow>
                            <TableCell colSpan={5}>
                                <NoItems />
                            </TableCell>
                        </TableRow>
                    ) : store.passwordsStore.passwords.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell className={styles.tableCell}>
                                <Badge>{item.browserName}</Badge>
                            </TableCell>

                            <TableCell className={styles.tableCell}>
                                {item.url}
                            </TableCell>

                            <TableCell className={styles.tableCell}>
                                {item.login}
                            </TableCell>

                            <TableCell className={styles.tableCell}>
                                {item.passwordValue}
                            </TableCell>

                            <TableCell className={styles.actionsCell}>
                                <LogOpenDetailsButton logId={item.botId}/>
                                <Button onClick={() => onDelete(item.id)} appearance={'subtle'} style={{color: tokens.colorPaletteDarkOrangeForeground1}} icon={<DeleteRegular />}></Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
}

export default observer(PasswordsList)
