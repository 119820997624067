import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    controlButtons: {
        display: "flex",
        flexWrap: "wrap",  // Контейнер кнопок адаптируется к размеру
        gap: "0.5em",
    },
    controlPagination: {
        display: "flex",
        gap: "1em",
        justifyContent: "flex-end",
    },
    controlContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "nowrap",
        gap: "0.5em",
    },
    fieldBlock: {
        display: 'flex',
        alignItems: 'center',
        gap: "0.5em"
    },
    modalDialog: {
        maxHeight: '80vh'
    }
});
